import React from "react";
import { Modal, Box, Typography, Card, CardContent, Alert, Button, Grid } from "@mui/material";
import { SxProps } from "@mui/system";
import { Close } from "@mui/icons-material";

interface ConfirmArticuleProps {
    isOpen: boolean;
    article: {
        title: string;
        subtitle: string;
        content: string;
    };
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmArticule: React.FC<ConfirmArticuleProps> = ({
    isOpen,
    article,
    onConfirm,
    onCancel
}) => {
    const modalStyles: SxProps = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        maxHeight: "80vh",
        overflow: "scroll",
        width: "70%",
        p: 2,
    };

    return (
        <Modal open={isOpen} onClose={onCancel}>
            <Card
                sx={modalStyles}>
                <CardContent>
                    <Box>
                        <Close
                            onClick={onCancel}
                            style={{ float: "right", margin: "10px 0px 10px 10px", cursor: "pointer" }} />
                        <Alert severity="info">
                            Vista previa del comunicado
                        </Alert>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <Button
                                variant="outlined"
                                onClick={onCancel}
                                sx={{ marginRight: "10px" }}
                            >
                                Seguir editando
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onConfirm}
                                sx={{ color: "white" }}
                            >
                                Confirmar Publicación
                            </Button>
                        </div>
                        <br />
                        <br />
                        <Typography variant="h4" component="h2" gutterBottom>
                            {article.title}
                        </Typography>
                        <Typography variant="h5" component="h3" gutterBottom>
                            {article.subtitle}
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: article.content }} />
                    </Box>
                </CardContent>
            </Card>
        </Modal >
    );
};

export default ConfirmArticule;
