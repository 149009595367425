import { Box, Switch, Popover, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import React, { useState } from "react";
import SyncUsers from "./SyncUsers";
import { sponsorTypeList } from "../SponsorType/CreateSponsorType";
import api from "../../services/api";

interface FiltersProps {
    setStatusFilter: any,
    statusFilter: boolean
    setVerifiedFilter: any,
    verifiedFilter: boolean,
    setRoleFilter: any,
    roleFilter: string,
    refreshUsers: () => void
}
const Filters = ({
    setStatusFilter,
    statusFilter,
    setVerifiedFilter,
    verifiedFilter,
    refreshUsers,
    roleFilter,
    setRoleFilter
}: FiltersProps
) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [sponsorTypeNames, set_sponsorTypeNames] = useState<any>({})
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        fetchSponsorTypes()

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStatusChange = (event: any) => {
        setStatusFilter(event.target.checked);
    };

    const handleVerifiedChange = (event: any) => {
        setVerifiedFilter(event.target.checked);
    };

    const fetchSponsorTypes = async () => {
        const response = await api.post(
            `/v1/role/list`
        );

        const sponsorTypeNamesList: any = {}

        sponsorTypeList.forEach((roleItemObject: string) => {
            const findedType = response.data.role.find((roleItem: any) => {
                return roleItemObject === roleItem.type
            })
            sponsorTypeNamesList[roleItemObject] = findedType?.name
        }, [response])

        set_sponsorTypeNames(sponsorTypeNamesList)

    }

    return (
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
            <Button sx={{ marginRight: "20px" }} onClick={handleClick}>Filtros</Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box p={2}>
                    <div style={{ marginBottom: "10px" }}>
                        <FormControl
                            style={{
                                width: "90%",
                                boxSizing: "border-box"
                            }}
                        >
                            <InputLabel>Rol</InputLabel>
                            <Select
                                value={roleFilter} // Valor inicial del número de resultados por página
                                onChange={(e) => setRoleFilter(e.target.value)}
                                style={{ marginLeft: '8px', width: "100%" }}
                            >
                                <MenuItem selected disabled value="rol">Rol</MenuItem>
                                <MenuItem value="admin">Administrador</MenuItem>
                                <MenuItem value="member">Miembro</MenuItem>
                                {
                                    sponsorTypeList.map((sponsorTypeItem, i) => <MenuItem key={i} value={sponsorTypeItem}>
                                        {sponsorTypeNames[sponsorTypeItem]}
                                    </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Switch defaultChecked={statusFilter} value={statusFilter} onChange={handleStatusChange} /> Estado
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Switch defaultChecked={verifiedFilter} value={verifiedFilter} onChange={handleVerifiedChange} /> Validado
                    </div>
                </Box>
            </Popover>
            <SyncUsers refreshUsers={refreshUsers} />

        </Box>
    );
};

export default Filters;
