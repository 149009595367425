import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField, IconButton, Divider, Grid, Card, Switch, FormControlLabel, TextareaAutosize, Autocomplete, Input, Snackbar } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import SponsorSelect from '../SponsorSelect/SponsorSelect';
import api from '../../services/api';
import { AddOutlined } from '@mui/icons-material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import FileNames from '../FileNames/FileNames';
import { sponsorTypeList } from '../../pages/SponsorType/CreateSponsorType';

interface CreateSponsorProps {
    buttonTitle: string;
    onCreate: () => void
}

const CreateSponsor: React.FC<CreateSponsorProps> = ({ buttonTitle, onCreate }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [content, setContent] = useState('');
    const [useHTML, set_useHTML] = useState(false);
    const [date, setDate] = useState<Date | null>();
    const [notification, setNotification] = useState<boolean>(true);
    const [users, setUsers] = useState<any[]>([]);
    const [filesWithName, set_filesWithName] = useState<any[]>([]);
    const [sponsor, setSponsor] = useState({
        name: '',
        id_user: '',
        logo: '',
        url: '',
        description: '',
        email: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        phone: '',
        web: '',
        youtube: ''
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState<'error' | 'success'>('error');
    const [message, setMessage] = useState('');

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const fetchUsers = async () => {
        const response = await api.post(
            `/v1/user/list`, { role: sponsorTypeList }
        );
        setUsers(response.data.users);
    }

    useEffect(() => {
        if (open) {
            set_useHTML(false)
            fetchUsers()
        }
    }, [open])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleSubtitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubtitle(event.target.value);
    };

    const handleEditorChange = (value: string) => {
        setContent(value);
    };


    // input handler
    const [file, set_file] = useState<any>(null)

    const clearSponsor = () => {
        setSponsor({
            name: '',
            id_user: '',
            logo: '',
            url: '',
            description: '',
            email: '',
            facebook: '',
            instagram: '',
            linkedin: '',
            twitter: '',
            phone: '',
            web: '',
            youtube: ''
        });
    }

    const handleFile = async (sponsorId: number) => {
        try {
            const formData = new FormData();
            formData.append('file', file.file);

            await api.post('/v1/file/create/sponsor/' + sponsorId + "?name=" + file.name, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

        } catch (error) {
            console.error('Failed to create sponsor:', error);
        }
    };


    const handleFileWithName = async (sponsorId: number, fileName: String, fileItem: any) => {
        try {
            const formData = new FormData();
            formData.append('file', fileItem);

            await api.post(`/v1/file/create-with-model/sponsor/${sponsorId}/${fileName}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

        } catch (error) {
            console.error('Failed to create sponsor:', error);
        }
    };

    const handleSave = async (e: any) => {
        try {
            e.preventDefault()
            const response = await api.post('/v1/sponsor/create', sponsor);

            await handleFile(response.data.sponsor.id)

            await Promise.all(filesWithName.map(async (fileItem) => {
                await handleFileWithName(response.data.sponsor.id, fileItem.name, fileItem.file)
            }))
            clearSponsor()

            onCreate();
            handleClose();
            // Display success message in the Snackbar
            setOpenSnackbar(true);
            setSeverity('success');
            setMessage('Patrocinador creado exitosamente');
        } catch (error) {
            console.error('Failed to create sponsor:', error);
            // Display success message in the Snackbar
            setOpenSnackbar(true);
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

        }
    };

    const handleFileChange = (event: any, name?: string) => {
        const file = event.target.files[0];
        set_file({ file, name })
    };


    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ color: "white" }}
            >
                {buttonTitle}
                <AddOutlined style={{ marginLeft: "10px" }} />
            </Button>
            <Modal open={open} onClose={handleClose}>
                <form onSubmit={handleSave}>
                    <Card sx={{
                        width: 700, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper', maxHeight: "80vh",
                        overflow: "scroll"
                    }}>
                        <Typography variant="h6" gutterBottom>
                            {buttonTitle}
                        </Typography>
                        <Divider />

                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nombre"
                                    value={sponsor.name}
                                    onChange={(e) => setSponsor({ ...sponsor, name: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    options={users.map((user) => { return { label: user.first_name + " " + user.last_name, value: user.id } })}
                                    renderInput={(params) => <TextField {...params} label="Responsable" required />}
                                    onChange={(e, n) => {
                                        setSponsor({ ...sponsor, id_user: n?.value })
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                Seleccionar foto de perfil
                                <br />
                                <input type="file" onChange={(e) => { handleFileChange(e, "profile_photo") }} />
                            </Grid>
                            <Grid item xs={12}>
                                <FileNames setChanges={(e) => { set_filesWithName(e) }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="URL"
                                    value={sponsor.url}
                                    onChange={(e) => setSponsor({ ...sponsor, url: e.target.value })}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Descripción"
                                    value={sponsor.description}
                                    onChange={(e) => setSponsor({ ...sponsor, description: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    value={sponsor.email}
                                    onChange={(e) => setSponsor({ ...sponsor, email: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Facebook"
                                    value={sponsor.facebook}
                                    onChange={(e) => setSponsor({ ...sponsor, facebook: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Instagram"
                                    value={sponsor.instagram}
                                    onChange={(e) => setSponsor({ ...sponsor, instagram: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Linkedin"
                                    value={sponsor.linkedin}
                                    onChange={(e) => setSponsor({ ...sponsor, linkedin: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Twitter"
                                    value={sponsor.twitter}
                                    onChange={(e) => setSponsor({ ...sponsor, twitter: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Teléfono"
                                    value={sponsor.phone}
                                    onChange={(e) => setSponsor({ ...sponsor, phone: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Web"
                                    value={sponsor.web}
                                    onChange={(e) => setSponsor({ ...sponsor, web: e.target.value })}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Youtube"
                                    value={sponsor.youtube}
                                    onChange={(e) => setSponsor({ ...sponsor, youtube: e.target.value })}

                                    fullWidth
                                />
                            </Grid>

                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                                Cancelar
                            </Button>
                            <Button
                                sx={{ color: "white" }}
                                size="small" variant="contained" color="primary"
                                type='submit'>
                                Crear
                            </Button>
                        </Box>
                    </Card>
                </form>
            </Modal>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </div >
    );
};

export default CreateSponsor;
