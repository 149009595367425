import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import { sponsorTypeList } from '../SponsorType/CreateSponsorType';
import { errorMessageFromBackend } from '../../utils/errorMessageFromBackend';


interface LoginProps {
    onLogin: (token: string) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const navigate = useNavigate();
    const [error, setError] = React.useState<string>('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [severity, setSeverity] = React.useState<AlertColor>('success');


    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {

            const formData = new FormData(event.currentTarget);
            const email = formData.get('email') as string;
            const password = formData.get('password') as string;

            const response = await api.post('/v1/user/login', {
                email: email.trim(),
                password: password.trim(),
                device: "panel"
            });

            // Assuming the response contains a token or any other authentication information
            const { token, user, role } = response.data;

            onLogin(token)

            // You can save the token in local storage or a state management solution
            // For example:
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify({ user, role }));
            // Navigate to the home page or perform any other necessary action

            if (user.role == "admin") {
                navigate('/home');
            } else {
                navigate('/patrocinadores');
            }
        } catch (error) {
            // Handle error case, e.g., display an error message
            setSeverity("error")
            setOpenSnackbar(true)
            setError(errorMessageFromBackend(error));
        }
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                Bienvenido de vuelta
            </Typography>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose} >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}>
                    {error}
                </MuiAlert>
            </Snackbar>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electrónico"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                // value="123456"
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Iniciar Sesión
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="/forgot-password" variant="body2">
                            Recuperar Contraseña
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Login;
