import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Box, Typography, TextField, IconButton, Divider, Grid, Card, Switch, FormControlLabel, Snackbar, Tooltip, FormControl, InputLabel, Select, MenuItem, TextareaAutosize } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import SponsorSelect from '../SponsorSelect/SponsorSelect';
import api from '../../services/api';
import { Notice } from '../../pages/Releases/Releases';
import MuiAlert from '@mui/material/Alert';
import { DonutSmallRounded } from '@mui/icons-material';
import SelectSponsorForNotice, { SponsorItem } from '../SelectSponsorForNotice/SelectSponsorForNotice';
import ConfirmArticule from '../ConfirmArticle/ConfirmArticle';

interface EditArticleProps {
    buttonTitle: string;
    article: Notice;
    onUpdate: () => void;
    openSnackbar: (value: boolean) => void;
    setMessage: (value: string) => void;
}

const EditArticle: React.FC<EditArticleProps> = ({
    buttonTitle,
    article,
    onUpdate,
    openSnackbar,
    setMessage,
}) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [loading, set_loading] = useState(false);
    const [category, setcategory] = useState('');
    const [showConfirmModal, set_showConfirmModal] = useState(false);
    const [content, setContent] = useState<any>('');
    const [date, setDate] = useState<Date | null | string | undefined>();
    const [notification, setNotification] = useState<boolean>(true);
    const [useHTML, set_useHTML] = useState(false);
    const [sponsors, setSponsors] = useState<SponsorItem[]>();
    const [selectedSponsor, setSelectedSponsor] = useState<SponsorItem | null>(null);

    useEffect(() => {
        const newArticle = { ...article };

        setTitle(newArticle.title);
        setSubtitle(newArticle.subtitle);
        setContent(newArticle.content);
        setDate(newArticle.send_at);
        setNotification(newArticle.notification);
    }, [article]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleEditorChange = (value: string) => {
        setContent(value);
    };

    const titleInputRef = useRef<HTMLInputElement>(null);
    const subtitleInputRef = useRef<HTMLInputElement>(null);
    const quillRef = useRef<ReactQuill | null>(null);

    const fetchSponsors = async () => {
        const sponsorsResponse = await api.post("v1/sponsor/list");
        setSponsors(sponsorsResponse.data.sponsor)
    }

    useEffect(() => {
        if (open) {
            if (titleInputRef.current) {
                titleInputRef.current.value = title;
            }
            if (subtitleInputRef.current) {
                subtitleInputRef.current.value = subtitle;
            }
            if (quillRef.current) {
                quillRef.current.getEditor().setContents(content);
            }
        }
    }, [title, content, open]);

    useEffect(() => {
        if (open) {
            set_useHTML(false);
            fetchSponsors()
            setcategory(article.category)
        }
    }, [open]);

    const handleSave = async () => {
        try {
            set_loading(true)
            const articleData = {
                type: 'platform',
                title: titleInputRef.current?.value || '',
                content: quillRef.current?.value || '',
                notification: notification,
                send_at: date ? date?.toString() : new Date().toISOString(),
                id_sponsor: ""
            };
            if (selectedSponsor && selectedSponsor.id) {
                articleData.id_sponsor = selectedSponsor.id
            }
            await api.put(`/v1/notice/edit/${article.id}`, articleData);
            setMessage('Artículo actualizado satisfactoriamente.');
            openSnackbar(true);
            handleClose();
            onUpdate();
            set_loading(false)
        } catch (error) {
            set_loading(false)
            console.error('Failed to edit article:', error);
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            openSnackbar(true);
        }
    };

    const setChart = () => {
        setTimeout(() => {
            const options = {
                series: [44, 55, 13, 43, 22],
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
            };
            const chart = new ApexCharts(document.querySelector('#chart'), options);

            chart.render();
        }, 100);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <Tooltip title="Editar">
                    <EditIcon color="primary" />
                </Tooltip>
            </IconButton>
            <Modal open={open} onClose={handleClose} disableEnforceFocus>
                <Card sx={{
                    width: 850, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper',
                    overflow: "scroll",
                    height: "80vh",
                }}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        set_showConfirmModal(true)
                    }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                            boxSizing: "border-box"
                        }}
                    >

                        <Grid container spacing={2} mt={2}>
                            <Typography variant="h6" ml={2} gutterBottom>
                                {buttonTitle}
                            </Typography>
                            <Divider />
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    disabled={loading}
                                    fullWidth label="Título" inputRef={titleInputRef} defaultValue={title} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={loading}
                                    fullWidth label="Subtítulo" inputRef={subtitleInputRef} defaultValue={subtitle} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    required
                                    disabled={loading}
                                    fullWidth>
                                    <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={category}
                                        label="Categoría"
                                        onChange={(event) => setcategory(event.target.value as string)}
                                    >
                                        <MenuItem value="aotec">AOTEC</MenuItem>
                                        <MenuItem value="formation">FORMACIÓN</MenuItem>
                                        <MenuItem value="event">EVENTOS</MenuItem>
                                        <MenuItem value="bulletin">BOLETÍNES</MenuItem>
                                        <MenuItem value="promotion_sponsor">PATROCINADORES</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                Editor <Switch checked={useHTML} onChange={(e) => set_useHTML(e.target.checked)} /> HTML
                                <br />
                                {useHTML ? (
                                    <TextareaAutosize
                                        value={content}
                                        onChange={(e) => handleEditorChange(e.target.value)}
                                        placeholder="Contenido"
                                        style={{
                                            padding: "10px",
                                            width: "100%"
                                        }} />
                                ) : (
                                    <ReactQuill theme="snow" value={content} onChange={handleEditorChange} placeholder="Contenido" />
                                )}
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <FormControlLabel
                                disabled={loading}
                                control={<Switch checked={notification} size="small" onChange={(e) => setNotification(e.target.checked)} />}
                                label="Notificar"
                            />
                            <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}
                                disabled={loading}>
                                Cancelar
                            </Button>
                            <ScheduleForm
                                onSelectDate={setDate}
                                disabled={loading} />
                            {/* <SelectSponsorForNotice
                            sponsors={sponsors}
                            setSelectedItem={setSelectedSponsor}
                            selectedSponsor={selectedSponsor}
                        /> */}
                            <Button sx={{ color: 'white' }} size="small" variant="contained" color="primary" type='submit'
                                disabled={loading}>
                                Actualizar
                            </Button>
                        </Box>
                    </form>
                </Card>
            </Modal>
            <ConfirmArticule
                isOpen={showConfirmModal}
                article={{ title, subtitle, content }}
                onConfirm={() => {
                    set_showConfirmModal(false)
                    handleSave()
                }}
                onCancel={() => {
                    set_showConfirmModal(false)
                }}
            />
        </div>
    );
};

export default EditArticle;
