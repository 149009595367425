import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import MarketplaceTable from './MarketplaceTable';
import ReleasesTable from './ReleasesTable';
import NewsTable from './NewsTable';
import api from '../../services/api';


interface ListProps {
    paginate: { itemCount: number },
    list: []
}
const Dashboard = () => {
    const [user, setUser] = React.useState<ListProps>();
    const [news, setNews] = React.useState<ListProps>();
    const [notice, setNotice] = React.useState<ListProps>();
    const [sponsors, setSponsors] = React.useState<ListProps>();
    const [market, setMarket] = React.useState<ListProps>();
    // marketplace pubs
    const get_marketplace = async () => {
        try {
            const response = await api.post('/v1/marketplace/list?limit=10&page=1');
            let post_data = {} as ListProps;
            post_data.paginate = response.data['paginate'];
            post_data.list = response.data['posts'];
            setMarket(post_data)
        } catch (error) {
            console.error(error)
        }
    }
    const get_sponsors = async () => {
        try {
            const response = await api.post('/v1/sponsor/list?limit=10&page=1');
            let post_data = {} as ListProps;
            post_data.paginate = response.data['paginate'];
            post_data.list = response.data['sponnsors'];
            setSponsors(post_data)
        } catch (error) {
            console.error(error)
        }
    }

    // comunicados -> posts:endpoint
    const get_notice = async () => {
        try {
            const response = await api.post('/v1/notice/list?limit=10&page=1');
            let post_data = {} as ListProps;
            post_data.paginate = response.data['paginate'];
            post_data.list = response.data['notices'];
            setNotice(post_data)
        } catch (error) {
            console.error(error)
        }
    }

    //  users
    const get_users = async () => {
        try {
            const response = await api.post('/v1/user/list?limit=20&page=1');
            let post_data = {} as ListProps;
            post_data.paginate = response.data['paginate'];
            post_data.list = response.data['users'];
            setUser(post_data)
        } catch (error) {
        }

    }
    const get_news = async () => {
        try {
            const response = await api.post('/v1/post/list');
            let post_data = {} as ListProps;
            post_data.paginate = response.data['paginate'];
            post_data.list = response.data['posts'];
            setNews(post_data)
        } catch (error) {
        }

    }
    
    useEffect(() => {
        get_users()
        get_news()
        get_notice()
        get_marketplace()
        get_sponsors()
        
    }, [])
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                    <Card sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                        <CardContent>
                            <Typography variant="body1">
                                Usuarios
                            </Typography>
                            <Typography variant="h2">{user?.paginate.itemCount}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                        <CardContent>
                            <Typography variant="body1">
                                Noticias
                            </Typography>
                            <Typography variant="h2">{news?.paginate.itemCount}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                        <CardContent>
                            <Typography variant="body1">
                                Comunicados
                            </Typography>
                            <Typography variant="h2">{notice?.paginate.itemCount}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                        <CardContent>
                            <Typography variant="body1">
                                MarketPlace
                            </Typography>
                            <Typography variant="h2">{market?.paginate.itemCount}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                        <CardContent>
                            <Typography variant="body1">
                                Patrocinadores
                            </Typography>
                            <Typography variant="h2">{sponsors?.paginate.itemCount}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <br />
            {/* <h4>
                Últimas publicaciones martek place
            </h4>
            <MarketplaceTable /> */}
            <h4>
                Últimos comunicados
            </h4>
            <ReleasesTable />
            <h4>
                Últimas noticias
            </h4>
            <NewsTable />

        </>
    );
};

export default Dashboard;
