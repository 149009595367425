import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    Snackbar,
    Select,
    MenuItem,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateArticle from '../../components/CreateArticle/CreateArticle';
import EditArticle from '../../components/EditArticle/EditArticle';
import api from '../../services/api';
import { formatDate, formatDateWithHour } from '../../utils/dateFormat';
import { AnalyticsOutlined, Check, ChevronRight, DonutLarge, DonutSmallOutlined, DonutSmallRounded, MoreVert } from '@mui/icons-material';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { ClockIcon } from '@mui/x-date-pickers';
import OrederBy from '../../components/Orderby/OrderBy';
import ReleasesAnalitics from '../../components/ReleasesAnalitics/ReleasesAnalitics';
import ReleaseMultiple from '../../components/Multiple';
import CreateCategory from '../../components/CreateCategory/CreateCategory';
import EditCategory from '../../components/EditCategory/EditCategory';
import { Link } from 'react-router-dom';

export interface Notice {
    id: number;
    type: string;
    name: string;
    created_at: string;
    modified_at: string;
    createdAt: string;
    updatedAt: string;
}

const Categories = () => {
    const [rows, setRows] = useState<Notice[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(-1);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });

    const [multiple, setMultiple] = useState<number[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const [clearSort, setclearSort] = useState(0);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        fetchCategories();
    }, [pagination.page, pagination.limit]);

    const fetchCategories = async () => {
        try {

            let orderQuery = {}
            if (sortColumn && sortType) {
                orderQuery = {
                    order: [sortColumn, sortType]
                }
            }

            const response = await api.post(`/v1/category/list?limit=${pagination.limit}&page=${pagination.page}`, {
                ...orderQuery
            });
            const { category, paginate } = response.data;

            setRows(category);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: paginate.pageCount,
            }));
        } catch (error) {
            console.error('Failed to fetch notices', error);
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleDelete = async () => {
        try {
            if (multiple.length > 0) {
                await Promise.all(
                    multiple.map(async (id) => {
                        await api.delete(`/v1/category/del/${id}`);
                    })
                );
                setMultiple([])
            } else {
                await api.delete(`/v1/category/del/${deleteItemId}`);
            }

            fetchCategories();
            setDeleteItemId(-1);
            closeDialog();
            setMessage('Comunicado eliminado satisfactoriamente');
            setSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
            console.error('Failed to delete notice', error);
        }
    };

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const handlePaginationChange = (page: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: page,
        }));
    };

    const handleCheckMultiple = (e: any, id: number) => {
        let newMultiple = [...multiple];
        if (e.target.checked) {
            newMultiple.push(id);
        } else {
            const index = newMultiple.findIndex((idItem) => idItem === id);
            if (index !== -1) {
                newMultiple.splice(index, 1);
            }
        }
        setMultiple(newMultiple);
    };

    const handleCheckAll = (selectAll: boolean) => {
        let newSelected: number[] = [];
        if (selectAll) {
            rows.forEach((rowItem) => {
                newSelected.push(rowItem.id);
            });
        }
        setMultiple(newSelected);
    };

    const handleResultsPerPageChange = (event: any) => {
        const resultsPerPage = parseInt(event.target.value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: resultsPerPage,
        }));
        setSortColumn('');
        setSortType('');
    };

    const clearSortEmit = () => {
        setclearSort(Date.now())
    }

    const handleSortClick = (column: string, type: string) => {
        setSortColumn(column);
        setSortType(type);
    }

    useEffect(() => {
        fetchCategories()
    }, [sortColumn, sortType])

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: "inline-flex",
                        justifyContent: "end",
                        width: "100%"
                    }}>
                        <CreateCategory
                            buttonTitle="Crear"
                            onCreate={() => {
                                fetchCategories();
                            }}
                        />
                        <ReleaseMultiple
                            multiple={multiple}
                            handleDelete={handleDelete} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <Table size="small" className='table-center'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            style={{ width: "5px", height: "5px" }}
                                            onChange={(e) => handleCheckAll(e.target.checked)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            label="Categoría"
                                            sortLabel="name"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="created_at"
                                            label="Creado"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="updatedAt"
                                            label="Modificado"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <Checkbox
                                                style={{ width: "5px", height: "5px" }}
                                                value={multiple.includes(row.id)}
                                                checked={multiple.includes(row.id)}
                                                onChange={(e) => handleCheckMultiple(e, row.id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/marketplace/${row.id}/${row.name.replace(/\s+/g, '-').toLowerCase()}`}>
                                                {row.name}
                                            </Link>
                                        </TableCell>

                                        <TableCell>{formatDateWithHour(row.created_at)}</TableCell>
                                        <TableCell>{formatDateWithHour(row.updatedAt)}</TableCell>
                                        <TableCell>
                                            <div style={{ display: "inline-flex" }}>
                                                {/* <ReleasesAnalitics
                                                    buttonTitle="Estadísticas"
                                                    total={row.views?.total}
                                                    totalNotRead={row.views?.totalNotRead}
                                                    usersRead={row.views?.usersRead}
                                                /> */}
                                                {multiple.length == 0 &&
                                                    <>
                                                        <Tooltip title="Eliminar">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setDeleteItemId(row.id);
                                                                    openDialog();
                                                                }}
                                                            >
                                                                <DeleteIcon color='primary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <EditCategory
                                                            onUpdate={() => {
                                                                fetchCategories();
                                                            }}
                                                            buttonTitle="Editar Categoría"
                                                            article={row}
                                                            openSnackbar={setOpenSnackbar}
                                                            setMessage={setMessage}
                                                        />
                                                        <Tooltip title="Abrir Categoría">
                                                            <IconButton href={`/marketplace/${row.id}/${row.name.replace(/\s+/g, '-').toLowerCase()}`}>
                                                                <ChevronRight color='primary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid >

            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={pagination.page === 1}
                        onClick={() => handlePaginationChange(pagination.page - 1)}
                    >
                        Anterior
                    </Button>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                        }}
                    >
                        Página {pagination.page} de {pagination.total}
                    </Box>
                    <Button
                        disabled={pagination.page === (pagination.total)}
                        onClick={() => handlePaginationChange(pagination.page + 1)}
                    >
                        Siguiente
                    </Button>
                    <Select
                        value={pagination.limit} // Valor inicial del número de resultados por página
                        onChange={handleResultsPerPageChange}
                        style={{ marginLeft: '8px' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Box>
            </Grid>

            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Realmente deseas eliminar este comunicado?
                </DialogTitle>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteItemId(-1);
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete();
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Categories;
