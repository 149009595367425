import * as React from 'react';
import { Navigate } from "react-router-dom";


interface CloseSessionProps {
    
}
 
interface CloseSessionState {
    
}
 
class CloseSession extends React.Component<CloseSessionProps, CloseSessionState> {

    componentDidMount(){
        localStorage.removeItem('token')
    }
    render() { 
        return (
            <Navigate to="/" />
    );
    }
}

export default CloseSession;