import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, Snackbar, TextField, IconButton, Divider, Grid, Card, Switch, FormControlLabel, TextareaAutosize, FormControl, InputLabel, Select, MenuItem, CardActions } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import SponsorSelect from '../SponsorSelect/SponsorSelect';
import api from '../../services/api';
import { AddOutlined } from '@mui/icons-material';
import SelectSponsorForNotice, { SponsorItem } from '../SelectSponsorForNotice/SelectSponsorForNotice';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ConfirmArticle from '../ConfirmArticle/ConfirmArticle';
import FileNames from '../FileNames/FileNames';

interface CreateArticleProps {
    buttonTitle: string;
    onCreate: () => void
}

const CreateArticle: React.FC<CreateArticleProps> = ({ buttonTitle, onCreate }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [content, setContent] = useState('');
    const [useHTML, set_useHTML] = useState(false);
    const [loading, set_loading] = useState(false);
    const [showConfirmModal, set_showConfirmModal] = useState(false);
    const [date, setDate] = useState<Date | null>();
    const [notification, setNotification] = useState<boolean>(true);
    const [sponsors, setSponsors] = useState<any>({});
    const [selectedSponsor, setSelectedSponsor] = useState<SponsorItem | null>(null);
    const [categories, setCategories] = useState<string>();
    const [filesWithName, set_filesWithName] = useState<any[]>([]);
    const [user, setUser] = useState<any>({});

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState<'error' | 'success'>('error');
    const [message, setMessage] = useState('');

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (open) {
            set_useHTML(false);

            const localStorageUser = localStorage.getItem("user")
            if (localStorageUser) {
                const userData = JSON.parse(localStorageUser)
                setUser(userData);
            }
        }
    }, [open])

    useEffect(() => {
        if (user?.user?.id) {
            fetchSponsors()
        }
    }, [user])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleSubtitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubtitle(event.target.value);
    };

    const handleEditorChange = (value: string) => {
        setContent(value);
    };

    const handleFileWithName = async (sponsorId: number, fileName: String, fileItem: any) => {
        try {
            const formData = new FormData();
            formData.append('file', fileItem);

            await api.post(`/v1/file/create-with-model/notice/${sponsorId}/${fileName}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

        } catch (error) {
            console.error('Failed to create notice:', error);
        }
    };

    const handleSave = async () => {
        try {
            set_loading(true)
            const articleData = {
                type: 'platform',
                title,
                subtitle,
                content,
                category: categories,
                notification: notification,
                send_at: date ? date?.toISOString() : new Date().toISOString(),
                id_sponsor: ""
            };
            if (selectedSponsor && selectedSponsor.id) {
                articleData.id_sponsor = selectedSponsor.id
            }

            const response = await api.post('/v1/notice/create', articleData);

            await Promise.all(filesWithName.map(async (fileItem) => {
                await handleFileWithName(response.data.notice.id, fileItem.name, fileItem.file)
            }))

            setTitle('');
            setSubtitle('');
            setContent('');
            setCategories('');
            setDate(null);
            setSelectedSponsor(null);
            onCreate();
            handleClose();

            // Display success message in the Snackbar
            setOpenSnackbar(true);
            setSeverity('success');
            setMessage('Comunicado creado exitosamente');
            set_loading(false)

        } catch (error) {

            console.error('Failed to create article:', error);
            // Display error message in the Snackbar
            setOpenSnackbar(true);
            set_loading(false)
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

        }
    };

    const fetchSponsors = async () => {

        const sponsorsResponse = await api.post("v1/sponsor/list", {
            id_user: user.user.id
        });

        if (sponsorsResponse.data?.sponsor?.[0]?.id) {
            const sponsorResponse = await api.get("v1/sponsor/" + sponsorsResponse.data.sponsor[0].id);

            setSponsors(sponsorResponse.data.infoLimits)

            if (user?.user?.role != "admin" && sponsorResponse.data.infoLimits?.noticeAvailable == 0) {
                set_loading(true)
            }
        }
    }

    const getPeriodName = (name: string) => {
        const names: { [key: string]: string } = {
            monthly: "Mensual",
            quarterly: "Trimestral",
            yearly: "Anual"
        }
        return names[name]
    }

    return (
        <div>

            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ color: "white" }}
            >
                {buttonTitle}
                <AddOutlined style={{ marginLeft: "10px" }} />
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Card sx={{
                    width: "75%", p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper',
                    height: "80vh",
                    overflow: "scroll",
                }}>
                    <Typography variant="h6" gutterBottom>
                        {buttonTitle}
                    </Typography>
                    <Divider />

                    {user?.user?.role != "admin" && <p>
                        <br />
                        Publicaciones restantes: <b>{sponsors?.noticeAvailable}</b> de <b>{sponsors?.quantityPublications}</b> ({getPeriodName(sponsors?.periodPublications)})
                    </p>}
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            set_showConfirmModal(true)
                        }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "calc(70% - 30px)",
                            boxSizing: "border-box"
                        }}
                    >
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    value={title}
                                    onChange={handleTitleChange}
                                    required
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Subtítulo"
                                    value={subtitle}
                                    onChange={handleSubtitleChange}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth
                                    required
                                    disabled={loading}
                                >
                                    <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categories}
                                        label="Categoría"
                                        onChange={(event) => setCategories(event.target.value as string)}
                                    >
                                        {user?.user?.role === "admin" && <MenuItem value="aotec">AOTEC</MenuItem>}
                                        {user?.user?.role === "admin" && <MenuItem value="formation">FORMACIÓN</MenuItem>}
                                        {user?.user?.role === "admin" && <MenuItem value="event">EVENTOS</MenuItem>}
                                        {user?.user?.role === "admin" && <MenuItem value="bulletin">BOLETÍNES</MenuItem>}
                                        <MenuItem value="promotion_sponsor">PATROCINADORES</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                Editor <Switch value={useHTML} onChange={(e) => { set_useHTML(e.target.checked) }} disabled={loading} /> HTML
                                <br />
                                {useHTML ?
                                    <TextareaAutosize
                                        value={content}
                                        onChange={(e) => handleEditorChange(e.target.value)}
                                        placeholder="Contenido"
                                        style={{
                                            padding: "10px",
                                            width: "100%",
                                        }} />
                                    :
                                    <ReactQuill
                                        theme="snow"
                                        value={content}
                                        onChange={handleEditorChange}
                                        placeholder="Contenido"
                                    />}

                            </Grid>
                            {(user?.user?.role === "admin" || user?.role?.permissions?.includes("files")) && <Grid item xs={12}>
                                <FileNames setChanges={(e) => { set_filesWithName(e) }} />
                            </Grid>}

                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, pb: 2 }}>
                            <FormControlLabel control={<Switch value={notification} size="small" defaultChecked
                                onChange={(e) => setNotification(e.target.checked)} />}
                                label="Notificar"
                                disabled={loading} />
                            <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}
                                disabled={loading}>
                                Cancelar
                            </Button>
                            <ScheduleForm
                                onSelectDate={setDate}
                                disabled={loading} />
                            {/* <SelectSponsorForNotice
                            sponsors={sponsors}
                            setSelectedItem={setSelectedSponsor}
                            selectedSponsor={selectedSponsor}
                        /> */}
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ mr: 2 }}
                                disabled={loading}
                                type='submit'
                            >
                                Vista Previa
                            </Button>
                            <Button
                                sx={{ color: "white" }}
                                size="small" variant="contained"
                                color="primary"
                                //  onClick={handleSave}>
                                type='submit'
                                disabled={loading}>


                                Crear
                            </Button>
                        </Box>
                    </form>


                </Card>
            </Modal>
            <ConfirmArticle
                isOpen={showConfirmModal}
                article={{ title, subtitle, content }}
                onConfirm={() => {
                    set_showConfirmModal(false)
                    handleSave()
                }}
                onCancel={() => {
                    set_showConfirmModal(false)
                }}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default CreateArticle;
