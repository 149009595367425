import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField, IconButton, Divider, Grid, Card, Switch, FormControlLabel, TextareaAutosize, Snackbar } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import SponsorSelect from '../SponsorSelect/SponsorSelect';
import api from '../../services/api';
import { AddOutlined } from '@mui/icons-material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface CreateCategoryProps {
    buttonTitle: string;
    onCreate: () => void
}

const CreateCategory: React.FC<CreateCategoryProps> = ({ buttonTitle, onCreate }) => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState<'error' | 'success'>('error');
    const [message, setMessage] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleSave = async () => {
        try {
            const categoryData = {
                name,
            };

            const response = await api.post('/v1/category/create', categoryData);
            onCreate()
            handleClose();
        } catch (error) {
            console.error('Failed to create article:', error);
            setOpenSnackbar(true);
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

        }
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ color: "white" }}
            >
                {buttonTitle}
                <AddOutlined style={{ marginLeft: "10px" }} />
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Card sx={{ width: 700, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                    <Typography variant="h6" gutterBottom>
                        {buttonTitle}
                    </Typography>
                    <Divider />
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>

                        <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                            Cancelar
                        </Button>
                        <Button
                            sx={{ color: "white" }}
                            size="small" variant="contained" color="primary" onClick={handleSave}>
                            Crear
                        </Button>
                    </Box>
                </Card>
            </Modal>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default CreateCategory;
