
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Divider,
  Grid,
  Card,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  CardContent,
  Table,
  TableContainer,
  TextareaAutosize,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ScheduleForm from "../../components/ScheduleForm/ScheduleForm";
import SponsorSelect from "../../components/SponsorSelect/SponsorSelect";
import ApexCharts from "apexcharts";
import api from "../../services/api";
import { Notice } from "../Releases/Releases";
import MuiAlert from "@mui/material/Alert";
import { Sponsor } from "./Sponsors";
import Advertisements from "./Advertisements";
import { sponsorTypeList } from "../SponsorType/CreateSponsorType";

interface EditArticleProps {
  buttonTitle: string;
  article: Sponsor;
  onUpdate: () => void;
  openSnackbar: (value: boolean) => void;
  setMessage: (value: string) => void;
  hideModal?: boolean,
  setSeverity: (value: string) => void;
}

const EditSponsor: React.FC<EditArticleProps | any> = ({
  buttonTitle,
  article,
  onUpdate,
  openSnackbar,
  setMessage,
  hideModal,
  setSeverity
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [content, setContent] = useState<any>("");
  const [tabValue, setTabValue] = useState(0);
  const [logs, setLogs] = useState<string[]>([]);
  const [date, setDate] = useState<Date | null | string | undefined>();
  const [users, setUsers] = useState<any[]>([]);
  const [user, setUser] = useState<any>({});
  const [useHTML, set_useHTML] = useState(false);
  const [sponsor, setSponsor] = useState<any>({});

  useEffect(() => {
    const editSponsor = { ...article };

    setSponsor({
      id: editSponsor.id,
      name: editSponsor.name,
      logo: editSponsor.logo,
      url: editSponsor.url,
      id_user: editSponsor.id_user
    });

    const localStorageUser = localStorage.getItem("user")
    if (localStorageUser) {
      const userData = JSON.parse(localStorageUser)
      setUser(userData);
    }

  }, []);

  const fetchUsers = async () => {
    const response = await api.post(
      `/v1/user/list`, { role: sponsorTypeList }
    );
    setUsers(response.data.users);
  }

  const handleOpen = () => {
    fetchUsers()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const titleInputRef = useRef<HTMLInputElement>(null);
  const subtitleInputRef = useRef<HTMLInputElement>(null);
  const quillRef = useRef<ReactQuill | null>(null);
  useEffect(() => {

    if (open) {

      Object.keys(article).forEach((key) => {
        const elementFinded = document.querySelector("#" + key)

        if (elementFinded) {
          // @ts-ignore
          elementFinded.value = article[key]
        }

      })

    }

  }, [open])

  useEffect(() => {
    if (open) {
      if (titleInputRef.current) {
        titleInputRef.current.value = title;
      }
      if (subtitleInputRef.current) {
        subtitleInputRef.current.value = subtitle;
      }
      if (quillRef.current) {
        quillRef.current.getEditor().setContents(content);
      }
    }
  }, [title, content, open]);

  useEffect(() => {
    if (open) {
      set_useHTML(false);
    }
  }, [open]);

  const handleSave = async (e: any) => {
    try {
      e.preventDefault()
      const items = Array.from(e.target)

      const data: { [key: string]: unknown } = {}


      items.filter((i: any) => i.tagName == "INPUT").forEach((item: any) => {
        if (item.id) {
          data[item.id] = item.value
        }
      })

      const findIdUser = users.find(user => user.first_name + " " + user.last_name === data.id_user)
      data.id_user = findIdUser.id

      await api.put(
        `/v1/sponsor/edit/${sponsor.id}`,
        data
      );

      setMessage("Patrocinador editado");
      setSeverity("success")
      openSnackbar(true);
      handleClose();
      onUpdate();
    } catch (error) {
      console.error("Failed to edit anuncio:", error);
      setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
      setSeverity("error");
      openSnackbar(true);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [file, set_file] = useState<any>(null)

  const handleFileChange = async (event: any) => {

    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);

      await api.post('/v1/file/create/sponsor/' + article.id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });

    } catch (error) {
      console.error('Failed to create sponsor:', error);
    }
    // set_file(file)
  };

  const buildDefault = (users: any) => {
    const findUser = users.find((user: any) => {
      return user.id == article.id_user
    })
    const findName = findUser?.first_name + " " + findUser?.last_name

    if (findName) {
      const data = {
        label: findName,
        value: article.id_user
      }
      return data
    }

  }

  const ModalContent = () => (<><Card
    sx={{
      width: 850,
      p: 2,
      mt: 10,
      mx: "auto",
      bgcolor: "background.paper",
      maxHeight: hideModal ? "" : "80vh",
      overflow: hideModal ? "" : "scroll"
    }}
  >
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Editar" />
        {(user?.user?.role === "admin" || user?.role?.permissions.includes("ads")) && <Tab label="Anuncios" />}

      </Tabs>
    </Box>
    <TabPanel value={tabValue} index={0}>
      {/* <Typography variant="h6" gutterBottom>
      {buttonTitle}
    </Typography>
    <Divider /> */}
      <form
        onSubmit={handleSave}
      >
        <TextField
          label="Nombre"
          id="name"
          defaultValue={article.name}
          fullWidth
          margin="normal"
        />
        {users.length > 0 &&
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              options={users.map((user) => { return { label: user.first_name + " " + user.last_name, value: user.id } })}
              renderInput={(params) => <TextField {...params} label="Responsable" required />}
              defaultValue={buildDefault(users)}
              id="id_user"
            />
          </Grid>
        }

        <Grid item xs={12}>
          <Button>
            <input type="file" onChange={handleFileChange} />
          </Button>
        </Grid>

        <TextField
          label="URL"
          id="url"
          defaultValue={article.url}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Descripción"
          id="description"
          defaultValue={article.description}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          id="email"
          defaultValue={article.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Facebook"
          id="facebook"
          defaultValue={article.facebook}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Instagram"
          id="instagram"
          defaultValue={article.instagram}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Linkedin"
          id="linkedin"
          defaultValue={article.linkedin}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Twitter"
          id="twitter"
          defaultValue={article.twitter}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Teléfono"
          id="phone"
          defaultValue={article.phone}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Web"
          id="web"
          defaultValue={article.web}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Youtube"
          id="youtube"
          defaultValue={article.youtube}
          fullWidth
          margin="normal"
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          {!hideModal && <Button
            size="small"
            variant="outlined"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
          }
          <Button
            sx={{ color: "white" }}
            size="small"
            variant="contained"
            color="primary"
            type="submit"
          >
            Actualizar
          </Button>
        </Box>
      </form>

    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <Grid container spacing={2}>
        <Advertisements sponsorId={sponsor.id} />
      </Grid>
      {!hideModal && <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          size="small"
          variant="outlined"
          onClick={handleClose}
          sx={{ mr: 2 }}
        >
          Cancelar
        </Button>
      </Box>}

    </TabPanel>
  </Card>
  </>)

  if (hideModal) {
    return <ModalContent />
  } else {
    return <>
      <IconButton onClick={handleOpen}>
        <EditIcon color="primary" />
      </IconButton>
      <Modal open={open} onClose={handleClose} disableEnforceFocus>
        <CardContent>
          <ModalContent />
        </CardContent>
      </Modal>
    </>
  }
};

export default EditSponsor;
