import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import api from '../../services/api';
import { errorMessageFromBackend } from '../../utils/errorMessageFromBackend';

export default function ResetPassword() {
    const { token } = useParams(); // Get the token from the URL params
    const navigate = useNavigate(); // Use history to navigate after resetting the password
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const password = data.get('password');
            const passwordConfirm = data.get('passwordConfirm');

            // Validate password and password confirmation
            if (!password || !passwordConfirm) {
                setError('Por favor ingresa una nueva contraseña y confírmala.');
                return;
            }

            if (password !== passwordConfirm) {
                setError('Las contraseñas no coinciden.');
                return;
            }

            // Send password reset request
            await api.post('/v1/user/edit/password-recover', {
                token,
                password,
            });

            // Open dialog on success
            setDialogOpen(true);
            setError('');
        } catch (error) {
            // Handle error case, e.g., display an error message
            setError(errorMessageFromBackend(error));
        }
    };

    const closeDialog = () => {
        setDialogOpen(false);
        navigate('/'); // Navigate to the login page after closing the dialog
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                Restablece tu contraseña
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Nueva Contraseña"
                    name="password"
                    autoComplete="password"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="passwordConfirm"
                    label="Confirmar Nueva Contraseña"
                    name="passwordConfirm"
                    autoComplete="password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Restablecer Contraseña
                </Button>

                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">
                        Tu contraseña se ha restablecido correctamente. Puedes iniciar sesión.
                    </DialogTitle>
                    <DialogActions>
                        <Link href="/">
                            <Button onClick={closeDialog} autoFocus>
                                Entendido
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>

                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}

                <Grid container>
                    <Grid item xs>
                        <Link href="/" variant="body2">
                            Iniciar Sesión
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
