import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    Snackbar,
    Select,
    MenuItem,
    Card,
    CardContent,
    Avatar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/api';
import { formatDate, formatDateWithHour } from '../../utils/dateFormat';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import CreateSponsor from '../../components/CreateSponsor/CreateSponsor';
import EditSponsor from './EditSponsor';
import Multiple from '../../components/Multiple';
import OrderBy from '../../components/Orderby/OrderBy';
import { FileOpenSharp, FileUpload } from '@mui/icons-material';
import EditFiles from './EditFiles';
import { sponsorTypeList } from '../SponsorType/CreateSponsorType';

export interface Sponsor {
    id: number;
    id_user: number;
    name: string;
    logo: string;
    url: string;
    created_at: string;
    created_by: number;
    modified_at: string;
    modified_by: number;
    createdAt: string;
    updatedAt: string;

    description: string;
    email: string;
    facebook: string;
    instagram: string;
    linkedin: string;
    twitter: string;
    phone: string
    web: string;
    youtube: string;

    viewst: {
        totalViewsAds: number
        totalViewsSponsor: number
    }
    user: {
        id: number;
        email: string;
        first_name: string;
        role: string;
    };
}

const Sponsors = () => {
    const [rows, setRows] = useState<Sponsor[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(-1);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });

    const [multiple, setMultiple] = useState<number[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');
    const [user, setUser] = React.useState<{ role: any, user: any }>({ role: {}, user: {} });

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    useEffect(() => {
        fetchSponsor();
        const localStorageUser = localStorage.getItem("user")
        if (localStorageUser) {
            const userData = JSON.parse(localStorageUser)
            setUser(userData);
        }
    }, [pagination.page, pagination.limit]);

    const fetchSponsor = async () => {
        try {
            let orderQuery: any = {
                forceNotAllowList: true
            }
            if (sortColumn && sortType) {
                orderQuery = {
                    order: [sortColumn, sortType],
                }
            }

            const localStorageUser = localStorage.getItem("user")
            if (localStorageUser) {
                const userData = JSON.parse(localStorageUser)

                if (sponsorTypeList.includes(userData.user.role)) {
                    orderQuery.id_user = userData.user.id
                }
            }

            const response = await api.post(`/v1/sponsor/list?limit=${pagination.limit}&page=${pagination.page}`,
                orderQuery);
            const { sponsor, paginate } = response.data;
            setRows(sponsor);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: response.data.paginate.pageCount,
            }));
        } catch (error) {
            console.error('Failed to fetch notices', error);
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

            setSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleDelete = async () => {
        try {
            if (multiple.length > 0) {
                await Promise.all(
                    multiple.map(async (id) => {
                        await api.delete(`/v1/sponsor/del/${id}`);
                    })
                );
                setMultiple([])
            } else {
                await api.delete(`/v1/sponsor/del/${deleteItemId}`);
            }

            fetchSponsor();
            setDeleteItemId(-1);
            closeDialog();
            setMessage('Patrocinador eliminado satisfactoriamente');
            setSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

            setSeverity('error');
            setOpenSnackbar(true);
            console.error('Failed to delete notice', error);
        }
    };

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const goToPage = (page: number) => {
        setPagination((pagination) => {
            return { ...pagination, page }
        });
    };

    const handleCheckMultiple = (e: any, id: number) => {
        let newMultiple = [...multiple];
        if (e.target.checked) {
            newMultiple.push(id);
        } else {
            const index = newMultiple.findIndex((idItem) => idItem === id);
            if (index !== -1) {
                newMultiple.splice(index, 1);
            }
        }
        setMultiple(newMultiple);
    };

    const handleCheckAll = (selectAll: boolean) => {
        let newSelected: number[] = [];
        if (selectAll) {
            rows.forEach((rowItem) => {
                newSelected.push(rowItem.id);
            });
        }
        setMultiple(newSelected);
    };

    const handleResultsPerPageChange = (event: any) => {
        const resultsPerPage = parseInt(event.target.value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: resultsPerPage
        }));
    };

    const [clearSort, setclearSort] = useState(0);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');

    const handleSortClick = (column: string, type: string) => {
        setSortColumn(column);
        setSortType(type);
    }

    useEffect(() => {
        fetchSponsor()
    }, [sortColumn, sortType])

    const clearSortEmit = () => {
        setclearSort(Date.now())
    }

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: "inline-flex",
                        justifyContent: "end",
                        width: "100%"
                    }}>
                        {user?.user?.role === "admin" && <CreateSponsor
                            buttonTitle="Crear"
                            onCreate={() => {
                                fetchSponsor();
                            }}
                        />}
                        <Multiple
                            multiple={multiple}
                            handleDelete={handleDelete} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table size="small" className='table-center'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    style={{ width: "5px", height: "5px" }}
                                                    onChange={(e) => handleCheckAll(e.target.checked)}
                                                />
                                            </TableCell>
                                            <TableCell>Logo</TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Nombre"
                                                    sortLabel="name"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>URL</TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Visitas de anuncios"
                                                    sortLabel="views_ads"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Visitas al perfil"
                                                    sortLabel="views"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Creado"
                                                    sortLabel="created_at"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Modificado"
                                                    sortLabel="modified_at"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Responsable"
                                                    sortLabel="created_by"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        style={{ width: "5px", height: "5px" }}
                                                        value={multiple.includes(row.id)}
                                                        checked={multiple.includes(row.id)}
                                                        onChange={(e) => handleCheckMultiple(e, row.id)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Avatar alt={row.name} src={row.logo} />
                                                    {/* <Avatar alt={row.name} src={row.logo.replace("http://apiaotec.6am.es:3020/", "https://backaotec.6am.es/")} /> */}
                                                </TableCell>
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={row.url}>Ir al sitio</a>
                                                </TableCell>
                                                <TableCell>
                                                    {row.viewst?.totalViewsAds}
                                                </TableCell>
                                                <TableCell>
                                                    {row.viewst?.totalViewsSponsor}
                                                </TableCell>
                                                <TableCell>{formatDateWithHour(row.created_at)}</TableCell>
                                                <TableCell>{formatDateWithHour(row.updatedAt)}</TableCell>
                                                <TableCell>{row.user?.email} <br /> {row.user?.first_name}</TableCell>
                                                <TableCell>
                                                    {multiple.length === 0 && <div style={{ display: "flex" }}>
                                                        {user?.user?.role === "admin" && <IconButton
                                                            onClick={() => {
                                                                setDeleteItemId(row.id);
                                                                openDialog();
                                                            }}
                                                        >
                                                            <DeleteIcon color='primary' />
                                                        </IconButton>}

                                                        <EditFiles model="sponsor" id={row.id} />
                                                        <EditSponsor
                                                            onUpdate={() => {
                                                                fetchSponsor();
                                                            }}
                                                            buttonTitle="Editar Patrocinador"
                                                            article={row}
                                                            openSnackbar={setOpenSnackbar}
                                                            setMessage={setMessage}
                                                            setSeverity={setSeverity}
                                                        />

                                                    </div>}

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >

            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={pagination.page === 1}
                        onClick={() => goToPage(pagination.page - 1)}
                    >
                        Anterior
                    </Button>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                        }}
                    >
                        Página {pagination.page} de {pagination.total}
                    </Box>
                    <Button
                        disabled={pagination.page === (pagination.total)}
                        onClick={() => goToPage(pagination.page + 1)}
                    >
                        Siguiente
                    </Button>
                    <Select
                        value={pagination.limit} // Valor inicial del número de resultados por página
                        onChange={handleResultsPerPageChange}
                        style={{ marginLeft: '8px' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Box>
            </Grid>

            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Realmente deseas eliminar este patrocinador?
                </DialogTitle>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteItemId(-1);
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete();
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Sponsors;
