import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    Snackbar,
    Select,
    MenuItem,
    Card,
    CardContent,
    Avatar,
    Chip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/api';
import { formatDate, formatDateWithHour } from '../../utils/dateFormat';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import CreateSponsor from '../../components/CreateSponsor/CreateSponsor';
import Multiple from '../../components/Multiple';
import OrderBy from '../../components/Orderby/OrderBy';
import EditSponsor from '../Sponsors/EditSponsor';
import CreateSponsorType from './CreateSponsorType';

export interface SponsorTypeInterface {
    id: number;
    description: string;
    name: string;
    permissions: string[];
    createdAt: string;
    modified_at: string
}

const SponsorType = () => {
    const [rows, setRows] = useState<SponsorTypeInterface[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(-1);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);

    const [multiple, setMultiple] = useState<number[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    useEffect(() => {
        fetchSponsor();
    }, [pagination.page, pagination.limit]);

    const fetchSponsor = async () => {
        try {

            let orderQuery = {}
            if (sortColumn && sortType) {
                orderQuery = {
                    order: [sortColumn, sortType]
                }
            }
            const response = await api.post(`/v1/role/list?limit=${pagination.limit}&page=${pagination.page}`,
                orderQuery);

            const { role, paginate } = response.data;

            setRows(role);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: paginate.pageCount,
            }));
        } catch (error) {
            console.error('Failed to fetch notices', error);
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleDelete = async () => {
        try {
            if (multiple.length > 0) {
                await Promise.all(
                    multiple.map(async (id) => {
                        await api.delete(`/v1/role/del/${id}`);
                    })
                );
                setMultiple([])
            } else {
                await api.delete(`/v1/role/del/${deleteItemId}`);
            }

            fetchSponsor();
            setDeleteItemId(-1);
            closeDialog();
            setMessage('Tipo eliminado satisfactoriamente');
            setSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
            console.error('Failed to delete notice', error);
        }
    };

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const goToPage = (page: number) => {
        setCurrentPage(page);
    };

    const handleCheckMultiple = (e: any, id: number) => {
        let newMultiple = [...multiple];
        if (e.target.checked) {
            newMultiple.push(id);
        } else {
            const index = newMultiple.findIndex((idItem) => idItem === id);
            if (index !== -1) {
                newMultiple.splice(index, 1);
            }
        }
        setMultiple(newMultiple);
    };

    const handleCheckAll = (selectAll: boolean) => {
        let newSelected: number[] = [];
        if (selectAll) {
            rows.forEach((rowItem) => {
                newSelected.push(rowItem.id);
            });
        }
        setMultiple(newSelected);
    };

    const handleResultsPerPageChange = (event: any) => {
        const resultsPerPage = parseInt(event.target.value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: resultsPerPage
        }));
    };

    const [clearSort, setclearSort] = useState(0);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');

    const handleSortClick = (column: string, type: string) => {
        setSortColumn(column);
        setSortType(type);
    }

    useEffect(() => {
        fetchSponsor()
    }, [sortColumn, sortType])

    const clearSortEmit = () => {
        setclearSort(Date.now())
    }

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: "inline-flex",
                        justifyContent: "end",
                        width: "100%"
                    }}>
                        <CreateSponsorType
                            buttonTitle="Crear"
                            onCreate={() => {
                                fetchSponsor();
                            }}
                        />
                        <Multiple
                            multiple={multiple}
                            handleDelete={handleDelete} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table size="small" className='table-center'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    style={{ width: "5px", height: "5px" }}
                                                    onChange={(e) => handleCheckAll(e.target.checked)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Nombre"
                                                    sortLabel="name"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Permisos"
                                                    sortLabel="permissions"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Creación"
                                                    sortLabel="createdAt"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OrderBy
                                                    label="Modificado"
                                                    sortLabel="modified_at"
                                                    clearSortEmit={clearSortEmit}
                                                    sortColumn={sortColumn}
                                                    sortType={sortType}
                                                    onSort={handleSortClick}
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        style={{ width: "5px", height: "5px" }}
                                                        value={multiple.includes(row.id)}
                                                        checked={multiple.includes(row.id)}
                                                        onChange={(e) => handleCheckMultiple(e, row.id)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>

                                                <TableCell>
                                                    {row.permissions.map((permissionItem) => <Chip key={permissionItem} label={permissionItem} variant="outlined" size="small" style={{ marginRight: "5px" }} />)}
                                                </TableCell>
                                                <TableCell>
                                                    {formatDateWithHour(row.createdAt)}
                                                </TableCell>
                                                <TableCell>
                                                    {formatDateWithHour(row.modified_at)}
                                                </TableCell>
                                                <TableCell>
                                                    {multiple.length === 0 && <div style={{ display: "flex" }}>
                                                        <IconButton
                                                            onClick={() => {
                                                                setDeleteItemId(row.id);
                                                                openDialog();
                                                            }}
                                                        >
                                                            <DeleteIcon color='primary' />
                                                        </IconButton>

                                                        <CreateSponsorType
                                                            isEdit={true}
                                                            typeToEdit={row}
                                                            onCreate={() => {
                                                                fetchSponsor();
                                                            }}
                                                        />


                                                    </div>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >

            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={currentPage === 1}
                        onClick={() => goToPage(currentPage - 1)}
                    >
                        Anterior
                    </Button>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                        }}
                    >
                        Página {pagination.page} de {pagination.total}
                    </Box>
                    <Button
                        disabled={pagination.page === (pagination.total)}
                        onClick={() => goToPage(currentPage + 1)}
                    >
                        Siguiente
                    </Button>
                    <Select
                        value={pagination.limit} // Valor inicial del número de resultados por página
                        onChange={handleResultsPerPageChange}
                        style={{ marginLeft: '8px' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Box>
            </Grid>

            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Realmente deseas eliminar este patrocinador?
                </DialogTitle>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteItemId(-1);
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete();
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SponsorType;
