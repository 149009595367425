import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    Card,
    CardContent,
    DialogContent,
    Select,
    MenuItem,
    Snackbar,
    Alert,
    Avatar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/api';
import { formatDateWithHour } from '../../utils/dateFormat';
import { AlertColor } from '@mui/material/Alert';
import Multiple from '../../components/Multiple';
import { useParams } from 'react-router-dom';
import OrederBy from '../../components/Orderby/OrderBy';
import { Check, Close } from '@mui/icons-material';

interface Image {
    id: number;
    id_marketplace: number;
    img: string;
    url: string;
    created_at: string;
    createdAt: string;
    updatedAt: string;
}

interface MarketplaceItem {
    id: number;
    type: string;
    title: string;
    description: string;
    id_category: number;
    price: number;
    created_at: string;
    created_by: number;
    modified_at: string;
    modified_by: number;
    createdAt: string;
    updatedAt: string;
    img: Image[];
    expired: boolean,
    category: Record<string, any>;
    views: { total: number }
}


const Marketplace: React.FC = () => {
    const [rows, setRows] = useState<MarketplaceItem[]>([]);
    const [filteredRows, setFilteredRows] = useState<MarketplaceItem[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState<number>(-1);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<MarketplaceItem | null>(null);
    const [password, setPassword] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [pictureUrl, setPictureUrl] = useState<string>("");
    const [dialogAlertOpen, setDialogAlertOpen] = React.useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });
    const [dialogMessage, setDialogMessage] = React.useState("");

    const [verifiedFilter, setVerifiedFilter] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState(false);

    // snack bar alert
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    // multiple selected by checkbox ids in string
    const [multiple, setmultiple] = useState<number[]>([]);

    const { categoryId } = useParams()

    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [clearSort, setclearSort] = useState(0);

    useEffect(() => {
        // Fetch the initial user list
        fetchMarketplace();
    }, [pagination.page, pagination.limit]); // Add pagination.page to the dependency array

    useEffect(() => {
        fetchMarketplace()
    }, [sortColumn, sortType])

    useEffect(() => {
        setPagination({
            page: 1,
            limit: 10,
            total: 0,
        });
        fetchMarketplace()
    }, [verifiedFilter, statusFilter])

    const handleCheckMultiple = (e: any, id: number) => {
        let newMultiple = [...multiple];
        if (e.target.checked) {
            newMultiple.push(id)
        } else {
            const index = newMultiple.findIndex((idItem) => idItem == id)
            if (index != -1) {
                newMultiple.splice(index, 1)
            }
        }
        setmultiple(newMultiple)
    }



    const fetchMarketplace = async () => {
        try {

            let body: any = {
                id_category: categoryId
            }

            if (verifiedFilter) {
                body.verified = true
            }

            if (statusFilter) {
                body.status = true
            }

            let orderQuery = {}
            if (sortColumn && sortType) {
                orderQuery = {
                    order: [sortColumn, sortType]
                }

            }

            const response = await api.post(
                `/v1/marketplace/list?limit=${pagination.limit}&page=${pagination.page}`, { ...body, ...orderQuery }
            );

            setRows(response.data.marketplace);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: response.data.paginate.pageCount,
            }));
        } catch (error) {
            console.error('Failed to fetch users', error);
        }
    };

    const handlePaginationChange = (page: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: page,
        }));
    };

    const handleDelete = async () => {
        try {
            if (multiple.length > 0) {
                await Promise.all(
                    multiple.map(async (id) => {
                        await api.delete(`/v1/marketplace/del/${id}`);
                    })
                );
                setmultiple([])
            } else {
                await api.delete(`/v1/marketplace/del/${deleteItemId}`);
            }

            fetchMarketplace();
            setDeleteItemId(-1);
            closeDialog();
            setMessage('Publicación eliminada satisfactoriamente');
            setSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
            console.error('Failed to delete notice', error);
        }
    };

    const clearSortEmit = () => {
        setclearSort(Date.now())
    }

    const handleSortClick = (column: string, type: string) => {
        setSortColumn(column);
        setSortType(type);
    }

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeAlertDialog = () => {
        setDialogAlertOpen(false);
    };



    const handleCheckAll = (selectAll: boolean) => {
        let newSelected: number[] = []
        if (selectAll) {
            rows.forEach((rowItem => {
                newSelected.push(rowItem.id)
            }))

        }
        setmultiple(newSelected)
    }

    const closeDialog = () => setDialogOpen(false);

    // snackbar handle
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };


    const handleResultsPerPageChange = (event: any) => {
        const resultsPerPage = parseInt(event.target.value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: resultsPerPage
        }));
    };

    return (
        <Grid container spacing={2}>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose} >
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Box sx={{
                        justifyContent: "end",
                        width: "100%"
                    }}>
                        <Multiple
                            multiple={multiple}
                            handleDelete={handleDelete} />
                    </Box>
                </Grid>

                <Card>
                    <CardContent>
                        <TableContainer>
                            <Table size='small' className='table-center'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                style={{ width: "5px", height: "5px" }}
                                                onChange={(e) => handleCheckAll(e.target.checked)} />
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Estado"
                                                sortLabel="expired"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Título"
                                                sortLabel="title"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Descripción"
                                                sortLabel="description"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Categoría"
                                                sortLabel="category"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Vísto"
                                                sortLabel="views"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Precio"
                                                sortLabel="price"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Creado"
                                                sortLabel="created_at"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <OrederBy
                                                label="Modificado"
                                                sortLabel="updatedAt"
                                                clearSortEmit={clearSortEmit}
                                                sortColumn={sortColumn}
                                                sortType={sortType}
                                                onSort={handleSortClick}
                                            />
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(filteredRows.length > 0 ? filteredRows : rows).map(
                                        (row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        style={{ width: "5px", height: "5px" }}
                                                        value={multiple.includes(row.id)} checked={multiple.includes(row.id)} onChange={(e) => handleCheckMultiple(e, row.id)} />
                                                </TableCell>
                                                <TableCell>
                                                    <Avatar alt={row.title} src={row.img[0]?.img} />
                                                </TableCell>
                                                <TableCell>{row.expired ? <Close /> : <Check />}</TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell>{row.category?.name}</TableCell>
                                                <TableCell>{row.views?.total}</TableCell>
                                                <TableCell>{row.price}</TableCell>
                                                <TableCell>{formatDateWithHour(row.created_at)}</TableCell>
                                                <TableCell>{formatDateWithHour(row.updatedAt)}</TableCell>
                                                <TableCell>

                                                    <div style={{ display: "flex" }}>
                                                        {multiple.length === 0 &&
                                                            <IconButton
                                                                onClick={() => {
                                                                    setDeleteItemId(row.id);
                                                                    openDialog();
                                                                }}
                                                            >
                                                                <DeleteIcon color='primary' />
                                                            </IconButton>
                                                        }


                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={pagination.page === 1}
                        onClick={() => handlePaginationChange(pagination.page - 1)}
                    >
                        Anterior
                    </Button>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px"
                    }}>
                        Página {pagination.page} de {pagination.total}
                    </Box>
                    <Button
                        disabled={pagination.page === (pagination.total)}
                        onClick={() => handlePaginationChange(pagination.page + 1)}
                    >
                        Siguiente
                    </Button>
                    <Select
                        value={pagination.limit} // Valor inicial del número de resultados por página
                        onChange={handleResultsPerPageChange}
                        style={{ marginLeft: '8px' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <Typography>¿Realmente quieres eliminar este usuario?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancelar</Button>
                    <Button
                        color="error"
                        variant="contained"
                        onClick={() => handleDelete()}
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogAlertOpen}
                onClose={closeAlertDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogMessage}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={closeAlertDialog} autoFocus>
                        Entendido
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid >
    );
};

export default Marketplace;
