import * as React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Home from './layouts/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Auth from './layouts/Auth/Auth';
import Login from './pages/Login/Login';
import FrogotPassword from './pages/ForgotPassword/ForgotPassword';
import Releases from './pages/Releases/Releases';
import Users from './pages/Users/Users';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Validate from './pages/Validate/Validate';
import { useEffect, useState } from 'react';
import CloseSession from './components/Auth/CloseSession';
import News from './pages/News/News';
import Marketplace from './pages/Marketplace/Marketplace';
import Sponsors from './pages/Sponsors/Sponsors';
import Categories from './pages/Categories/Categories';
import Profile from './pages/Profile/Profile';
import SponsorType from './pages/SponsorType/SponsorType';

const MarletPlaceHome = () => {

  const { name } = useParams()

  return <Home title={'Marketplace / ' + name} >
    <Marketplace />
  </Home >
}

export default function App() {
  const [isLogin, setIsLogin] = useState<string>(localStorage.getItem('token') || "")

  const router = createBrowserRouter([
    {
      path: "/",
      element:
        <Auth>
          <Login onLogin={(token) => {
            setIsLogin(token)
          }} />
        </Auth>
    },
    {
      path: "/logout",
      element: <CloseSession />
    },
    {
      path: "/forgot-password",
      element: <Auth>
        <FrogotPassword />
      </Auth>
    },
    {
      path: "/reset-password/:token",
      element: <Auth>
        <ResetPassword />
      </Auth>
    },
    {
      path: "/validate/:token",
      element: <Auth>
        <Validate />
      </Auth>
    },
    {
      path: "/home",
      element:
        <Home title='Bienvenido'>
          {isLogin != "" ? <Dashboard /> : <Navigate to='/' />}
        </Home>
    },
    {
      path: "/releases",
      element:
        <Home title='Comunicados'>
          {isLogin != "" ? <Releases /> : <Navigate to='/' />}
        </Home>
    },

    {
      path: "/marketplace",
      element:
        <Home title='Marketplace'>
          {isLogin != "" ? <Categories /> : <Navigate to='/' />}
        </Home>
    },
    {
      path: "/marketplace/:categoryId/:name",
      element: <>{isLogin != "" ? <MarletPlaceHome /> : <Navigate to='/' />}</>

    },
    {
      path: "/news",
      element:
        <Home title='Noticias'>
          {isLogin != "" ? <News /> : <Navigate to='/' />}
        </Home>
    },
    {
      path: "/users",
      element:
        <Home title='Usuarios'>
          {isLogin != "" ? <Users /> : <Navigate to='/' />}
        </Home>
    },
    {
      path: "/profile",
      element:
        <Home title='Perfil'>
          {isLogin != "" ? <Profile /> : <Navigate to='/' />}
        </Home>
    },
    {
      path: "/patrocinadores",
      element:
        <Home title='Patrocinadores'>
          {isLogin != "" ? <Sponsors /> : <Navigate to='/' />}
        </Home>
    },
    {
      path: "/tipos-patrocinadores",
      element:
        <Home title='Tipos de patrocinadores'>
          {isLogin != "" ? <SponsorType /> : <Navigate to='/' />}
        </Home>
    },
  ]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  );
}
