import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import api from '../../services/api';
import { errorMessageFromBackend } from '../../utils/errorMessageFromBackend';

export default function ForgotPassword() {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [email, setEmail] = React.useState('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            // Basic form validation
            if (!email) {
                setError('Por favor ingresa un correo electrónico.');
                return;
            }

            // Send password recovery request
            await api.post('/v1/user/send/password-recover', { email });

            // Open dialog on success
            setDialogOpen(true);
            setError('');
        } catch (error) {
            // Handle error case, e.g., display an error message
            setError(errorMessageFromBackend(error));
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setError('');
    };

    const closeDialog = () => setDialogOpen(false);

    return (
        <>
            <Typography component="h1" variant="h5">
                Recupera tu contraseña
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electrónico"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleEmailChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Recuperar Contraseña
                </Button>

                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Hemos enviado un correo electrónico para restablecer tu contraseña
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={closeDialog} autoFocus>
                            Entendido
                        </Button>
                    </DialogActions>
                </Dialog>

                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}

                <Grid container>
                    <Grid item xs>
                        <Link href="/" variant="body2">
                            Iniciar Sesión
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
