import React, { useState } from "react";
import { FileOpenSharp } from "@mui/icons-material";
import { AlertColor, Button, IconButton, Modal, Paper, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import FileNames from "../../components/FileNames/FileNames";
import api from "../../services/api";

const EditFiles = ({ id, model }: { id: number, model: string }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelData, set_model] = useState({
        files: []
    });

    const [filesData, set_filesData] = useState<any[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const fetchModel = async () => {
        const response = await api.get(`/v1/${model}/${id}`);
        set_model(response.data)
    }

    const openModal = async () => {
        await fetchModel();
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const onSave = async () => {
        setIsSaving(true);

        try {
            await Promise.all(filesData.map(async (fileItem: any) => {
                if (fileItem.deleted) {
                    await handleDeleteExistingItem(fileItem.id);
                } else if (fileItem.new) {
                    const formData = new FormData();
                    formData.append('file', fileItem.file);

                    await api.post(`/v1/file/create-with-model/${model}/${id}/${fileItem.name}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                } else if (!fileItem.new) {
                    await api.put(`/v1/file/edit/${fileItem.id}`, {
                        name: fileItem.name,
                    });
                }
            }));

            setIsModalOpen(false);
            setSnackbarSeverity("success");
            setSnackbarMessage("Imagenes cargadas correctamente");
            setSnackbarOpen(true);
        } catch (error) {
            setIsModalOpen(false);
            setSnackbarSeverity("error");
            setSnackbarMessage("Ha ocurrido un error inesperado.");
            setSnackbarOpen(true);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteExistingItem = async (id: number) => {
        await api.delete(`/v1/file/del/${id}`);
    };

    return (
        <div>
            <IconButton onClick={openModal}>
                <FileOpenSharp color="primary" />
            </IconButton>
            <Modal open={isModalOpen} onClose={closeModal}>
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <Paper style={{ padding: "20px" }}>
                        <h2>Editar Archivos</h2>
                        <FileNames
                            initialFiles={modelData.files.map((fileItem: any) => ({ name: fileItem.name, url: fileItem.path, id: fileItem.id }))}
                            setChanges={(e) => {
                                set_filesData(e);
                            }}
                        />
                        <Button onClick={closeModal}>Cancelar</Button>
                        <Button onClick={onSave} disabled={isSaving}>Guardar Cambios</Button>

                        {isSaving && <>Subiendo archivos... </>}

                    </Paper>
                </div>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity as AlertColor}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default EditFiles;
