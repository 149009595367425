import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Checkbox,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField,
    Card,
    CardContent,
    DialogContent,
    Modal,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    Snackbar,
    Alert,
    Avatar,
    FormControl,
    InputLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import api from '../../services/api';
import { CheckOutlined, EmailOutlined, MarkEmailUnreadOutlined, RadioButtonUnchecked, Sync, Upgrade } from '@mui/icons-material';
import { formatDateWithHour } from '../../utils/dateFormat';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Filters from './Filters';
import Multiple from '../../components/Multiple';
import OrderBy from '../../components/Orderby/OrderBy';
import CreateUser from '../../components/CreateUser/CreateUser';
import UsersTable from './UsersTable';
import { getSponsorTypeName, sponsorTypeList } from '../SponsorType/CreateSponsorType';



const roleNames: { [key: string]: string } = {
    member: "Miembro",
    admin: "Administrador",
    sponsor: "Patrocinador",
}

interface User {
    id: number;
    role: string;
    email: string;
    first_name: string;
    last_name: string | null;
    company_name: string | null;
    picture_url: string;
    status: boolean;
    verified: boolean;
    password?: string;
    clientify_created: string | null;
    clientify_id: string | null;
    created_at: string;
    modified_at: string;
    last_access: string;
    createdAt: string;
    updatedAt: string;
    validated_at: string;
    permissions: any[]
}

const Users: React.FC = () => {
    const [rows, setRows] = useState<User[]>([]);
    const [filteredRows, setFilteredRows] = useState<User[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState<number>(-1);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [password, setPassword] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [pictureUrl, setPictureUrl] = useState<string>("");
    const [dialogAlertOpen, setDialogAlertOpen] = React.useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });
    const [dialogMessage, setDialogMessage] = React.useState("");
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
    const [userToDelete, setUserToDelete] = React.useState(-1);

    const [verifiedFilter, setVerifiedFilter] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState(false);
    const [roleFilter, setRoleFilter] = React.useState("");

    const [clearSort, setclearSort] = useState(0);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    // snack bar alert
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const [loading, setLoading] = React.useState(false);

    // multiple selected by checkbox ids in string
    const [multiple, setMultiple] = useState<number[]>([]);

    const [sponsorTypeNames, set_sponsorTypeNames] = useState<any>({})


    useEffect(() => {
        // Fetch the initial user list
        fetchUsers();
    }, [pagination.page, pagination.limit]); // Add pagination.page to the dependency array

    useEffect(() => {
        setPagination({
            page: 1,
            limit: 10,
            total: 0,
        });
        fetchUsers()
    }, [verifiedFilter, statusFilter, roleFilter])

    const handleCheckMultiple = (e: any, id: number) => {
        let newMultiple = [...multiple];
        if (e.target.checked) {
            newMultiple.push(id)
        } else {
            const index = newMultiple.findIndex((idItem) => idItem == id)
            if (index != -1) {
                newMultiple.splice(index, 1)
            }
        }
        setMultiple(newMultiple)
    }

    const searchUserById = (id: number) => {
        const row = rows.find(item => item.id == id)
        if (row) {
            return row
        }
    }

    const handleSendValidation = async (id?: number) => {
        try {
            if (multiple.length > 0) {
                await Promise.all(
                    multiple.map(async (id) => {
                        if (!searchUserById(id)?.verified) {
                            await api.post(`/v1/user/re-send/verification-user`, {
                                email: searchUserById(id)?.email,
                            });
                        }
                    })
                );
                setMultiple([])
            } else {
                if (id) {
                    await api.post(
                        `/v1/user/re-send/verification-user`,
                        {
                            email: searchUserById(id)?.email
                        }
                    );
                }
            }

            setDialogMessage("Se ha enviado correctamente la confirmación")
            setDialogAlertOpen(true)
        } catch (error) {
            setDialogMessage("Ha ocurrido un error inesperado al intentar enviar confirmación a los usuarios")
            setDialogAlertOpen(true)
        }
    }

    const fetchUsers = async (searchText?: string) => {
        try {
            const queryParams = {
                limit: pagination.limit,
                page: pagination.page,
            };

            let endpoint = "/v1/user/list";
            if (searchText) {
                endpoint = `/v1/user/search/${searchText}`;
            }

            let requestData: {
                method: string,
                url: string,
                params: { limit: number, page: number },
                data?: any
            } = {
                method: searchText ? "GET" : "POST",
                url: endpoint,
                params: queryParams
            }

            if (requestData.method == "POST") {
                requestData.data = {
                    ...(verifiedFilter && { verified: true }),
                    ...(statusFilter && { status: true }),
                    ...(roleFilter && { role: roleFilter }),
                    ...(sortColumn && sortType && { order: [sortColumn, sortType] })
                }
            }

            const response = await api.request(requestData);

            setRows(response.data.users);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: response.data.paginate.pageCount,
            }));
        } catch (error) {
            console.error('Failed to fetch users', error);
        }
    };

    const fetchSponsorTypes = async () => {
        const response = await api.post(
            `/v1/role/list`
        );

        const sponsorTypeNamesList: any = {}

        sponsorTypeList.forEach((roleItemObject: string) => {
            const findedType = response.data.role.find((roleItem: any) => {
                return roleItemObject === roleItem.type
            })
            sponsorTypeNamesList[roleItemObject] = findedType?.name
        }, [response])

        set_sponsorTypeNames(sponsorTypeNamesList)
        set_sponsorRoles(response.data.role)


    }

    useEffect(() => {
        fetchUsers()
    }, [sortColumn, sortType])


    const handlePaginationChange = (page: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: page,
        }));
    };

    const openDialog = (id: number) => {
        setDeleteItemId(id);
        setDialogOpen(true);
    };

    const closeAlertDialog = () => {
        setDialogAlertOpen(false);
    };

    const handleEdit = (id: number) => {
        const user = rows.find((row) => row.id === id);
        setSelectedUser(user || null);
        if (user?.picture_url) {
            setPictureUrl(user.picture_url)
        }
        setPassword('');
        setIsActive(user?.status || false);
        setIsVerified(user?.verified || false);
        setEditModalOpen(true);
    };

    const [file, set_file] = useState<any>(null)
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        set_file(file)
    };

    const handleFile = async (userId: number) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            await api.post('/v1/file/create/user/' + userId, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

        } catch (error) {
            console.error('Failed to create sponsor:', error);
        }
    };

    const [sponsorRoles, set_sponsorRoles] = useState<any>([])



    useEffect(() => {
        if (editModalOpen) {
            fetchSponsorTypes()

        }
    }, [editModalOpen])

    useEffect(() => {
        if (selectedUser?.role) {
            setRole(selectedUser.role)
        }
    }, [selectedUser])

    const handleSaveEdit = async () => {
        if (loading) return
        setLoading(true)
        if (!selectedUser) return;

        try {
            let updatedUser = {
                ...selectedUser,
                status: isActive,
                verified: isVerified,
                role
            };

            if (updatedUser.password) {
                delete updatedUser.password
            }

            if (password && password != "") {
                updatedUser.password = password
            }

            await api.put(
                `/v1/user/edit/${selectedUser.id}`,
                updatedUser
            );

            if (pictureUrl) {
                await handleFile(selectedUser.id)
            }


            fetchUsers()
            setEditModalOpen(false);
            setSeverity("success")
            setMessage('Usuario actualizado exitosamente.')
            setOpenSnackbar(true)
            setLoading(false)

        } catch (error) {
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error')
            setLoading(false)
            setOpenSnackbar(true)
            console.error('Failed to save user edit', error);
        }
    };

    const handleCheckAll = (selectAll: boolean) => {
        let newSelected: number[] = []
        if (selectAll) {
            rows.forEach((rowItem => {
                newSelected.push(rowItem.id)
            }))

        }
        setMultiple(newSelected)
    }


    const handleSortClick = (column: string, type: string) => {
        setSortColumn(column);
        setSortType(type);
    }


    const closeDialog = () => setDialogOpen(false);
    // snackbar handle
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        const filtered = rows.filter(
            (row) =>
                row.email.toLowerCase().includes(query.toLowerCase()) ||
                row.first_name.toLowerCase().includes(query.toLowerCase()) ||
                row.last_name?.toLowerCase().includes(query.toLowerCase())
        );

        setFilteredRows(filtered);
    };

    const handleResultsPerPageChange = (event: any) => {
        const resultsPerPage = parseInt(event.target.value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: resultsPerPage
        }));
        setSortColumn('');
        setSortType('');
    };


    const clearSortEmit = () => {
        setclearSort(Date.now())
    }

    const handleDelete = async (id: number) => {
        try {
            setLoading(true)
            await api.delete("/v1/user/del/" + id)
            await fetchUsers()
            setLoading(false)
            setMessage("Usuario eliminado correctamente.");
            setSeverity('success')
            setOpenSnackbar(true)
        } catch (error) {
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error')
            setLoading(false)
            setOpenSnackbar(true)
            console.error('Failed to delete user', error);
        }
    }

    return (
        <Grid container spacing={2}>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose} >
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={{
                    display: "inline-flex",
                    justifyContent: "end",
                    width: "100%"
                }}>
                    <Filters
                        roleFilter={roleFilter}
                        setRoleFilter={setRoleFilter}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        verifiedFilter={verifiedFilter}
                        setVerifiedFilter={setVerifiedFilter}
                        refreshUsers={fetchUsers}
                    />

                    <div
                        style={{ marginLeft: "10px" }}
                    >
                        <CreateUser buttonTitle='Crear' onCreate={fetchUsers} />
                    </div>
                    <Multiple
                        multiple={multiple}
                        handleSendEmail={handleSendValidation} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <UsersTable
                            handleCheckAll={handleCheckAll}
                            clearSortEmit={clearSortEmit}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            handleSortClick={handleSortClick}
                            filteredRows={filteredRows}
                            rows={rows}
                            multiple={multiple}
                            roleNames={roleNames}
                            handleCheckMultiple={handleCheckMultiple}
                            handleEdit={handleEdit}
                            handleSendValidation={handleSendValidation}
                            fetchUsers={fetchUsers}
                            handleDelete={(id: number) => {
                                setUserToDelete(id)
                                setDialogDeleteOpen(true)
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={pagination.page === 1}
                        onClick={() => handlePaginationChange(pagination.page - 1)}
                    >
                        Anterior
                    </Button>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px"
                    }}>
                        Página {pagination.page} de {pagination.total}
                    </Box>
                    <Button
                        disabled={pagination.page === (pagination.total)}
                        onClick={() => handlePaginationChange(pagination.page + 1)}
                    >
                        Siguiente
                    </Button>
                    <Select
                        value={pagination.limit} // Valor inicial del número de resultados por página
                        onChange={handleResultsPerPageChange}
                        style={{ marginLeft: '8px' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Editar Usuario
                            </Typography>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={selectedUser?.email || ''}
                                disabled
                            />
                            <TextField
                                label="Nombre"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={selectedUser?.first_name || ''}
                                disabled
                            />
                            <TextField
                                label="Apellido"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={selectedUser?.last_name || ''}
                                disabled
                            />
                            <Grid item xs={12}>
                                <Button>
                                    <input
                                        disabled={loading} type="file" onChange={handleFileChange} />
                                </Button>
                            </Grid>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={loading}
                                        checked={isActive}
                                        onChange={(e) => setIsActive(e.target.checked)}
                                    />
                                }
                                label="Activo"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={loading}
                                        checked={isVerified}
                                        onChange={(e) => setIsVerified(e.target.checked)}
                                    />
                                }
                                label="Verificado"
                            />
                            <TextField
                                disabled={loading}
                                label="Contraseña"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FormControl
                                style={{
                                    width: "100%",
                                    boxSizing: "border-box"
                                }}
                            >
                                <InputLabel>Rol</InputLabel>
                                <Select
                                    label="Rol"
                                    value={role} // Valor inicial del número de resultados por página
                                    onChange={(e) => setRole(e.target.value + "")}
                                    style={{ width: "100%" }}
                                >
                                    <MenuItem selected disabled value="rol">Rol</MenuItem>
                                    <MenuItem value="admin">Administrador</MenuItem>
                                    <MenuItem value="member">Miembro</MenuItem>
                                    {
                                        sponsorTypeList.map((sponsorTypeItem, i) => <MenuItem key={i} value={sponsorTypeItem}>
                                            {sponsorTypeNames[sponsorTypeItem]}
                                        </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </CardContent>
                        <DialogActions>
                            <Button onClick={() => setEditModalOpen(false)}>Cancelar</Button>
                            <Button onClick={handleSaveEdit}>{loading ? <> <Sync className='rotate-animation' style={{ marginRight: "20px" }} /> Guardando</> : "Guardar"}</Button>
                        </DialogActions>
                    </Card>
                </Box>
            </Modal>

            <Dialog
                open={dialogDeleteOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Realmente deseas eliminar este usuario?
                </DialogTitle>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setUserToDelete(-1);
                            setDialogDeleteOpen(false)
                        }}
                        autoFocus
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete(userToDelete);
                            setDialogDeleteOpen(false)
                        }}
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogAlertOpen}
                onClose={closeAlertDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogMessage}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={closeAlertDialog} autoFocus>
                        Entendido
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid >
    );
};

export default Users;
