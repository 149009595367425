import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Box, Typography, TextField, IconButton, Divider, Grid, Card, Switch, FormControlLabel, Snackbar, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import SponsorSelect from '../SponsorSelect/SponsorSelect';
import api from '../../services/api';
import MuiAlert from '@mui/material/Alert';
import { DonutSmallRounded } from '@mui/icons-material';

interface EditCategoryProps {
    buttonTitle: string;
    article: any;
    onUpdate: () => void;
    openSnackbar: (value: boolean) => void;
    setMessage: (value: string) => void;
}

const EditCategory: React.FC<EditCategoryProps> = ({
    buttonTitle,
    article,
    onUpdate,
    openSnackbar,
    setMessage,
}) => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [content, setContent] = useState<any>('');
    const [date, setDate] = useState<Date | null | string | undefined>();
    const [notification, setNotification] = useState<boolean>(true);
    const [useHTML, set_useHTML] = useState(false);

    useEffect(() => {
        const newCategory = { ...article };

        setName(newCategory.name);
        setSubtitle(newCategory.subtitle);
        setContent(newCategory.content);
        setDate(newCategory.send_at);
        setNotification(newCategory.notification);
    }, [article]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const titleInputRef = useRef<HTMLInputElement>(null);
    const subtitleInputRef = useRef<HTMLInputElement>(null);
    const quillRef = useRef<ReactQuill | null>(null);

    useEffect(() => {
        if (open) {
            if (titleInputRef.current) {
                titleInputRef.current.value = name;
            }
            if (subtitleInputRef.current) {
                subtitleInputRef.current.value = subtitle;
            }
            if (quillRef.current) {
                quillRef.current.getEditor().setContents(content);
            }
        }
    }, [name, content, open]);

    useEffect(() => {
        if (open) {
            set_useHTML(false);
        }
    }, [open]);

    const handleSave = async () => {
        try {
            const articleData = {
                name: titleInputRef.current?.value || '',
            };

            await api.put(`/v1/category/edit/${article.id}`, articleData);
            setMessage('Categoría actualizada satisfactoriamente.');
            openSnackbar(true);
            handleClose();
            onUpdate();
        } catch (error) {
            console.error('Failed to edit article:', error);
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            openSnackbar(true);
        }
    };

    const setChart = () => {
        setTimeout(() => {
            const options = {
                series: [44, 55, 13, 43, 22],
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
            };
            const chart = new ApexCharts(document.querySelector('#chart'), options);

            chart.render();
        }, 100);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <Tooltip title="Editar">
                    <EditIcon color="primary" />
                </Tooltip>
            </IconButton>
            <Modal open={open} onClose={handleClose} disableEnforceFocus>
                <Card sx={{ width: 850, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                    <Typography variant="h6" gutterBottom>
                        {buttonTitle}
                    </Typography>
                    <Divider />
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Name" inputRef={titleInputRef} defaultValue={name} />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                            Cancelar
                        </Button>
                        <Button sx={{ color: 'white' }} size="small" variant="contained" color="primary" onClick={handleSave}>
                            Actualizar
                        </Button>
                    </Box>
                </Card>
            </Modal>
        </div>
    );
};

export default EditCategory;
