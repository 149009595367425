import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField, Divider, Grid, Card, Select, MenuItem, FormControl, InputLabel, Autocomplete, Snackbar, Alert } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import api from '../../services/api';
import { AddOutlined } from '@mui/icons-material';

interface CreateSponsorProps {
    buttonTitle: string;
    onCreate: () => void;
    sponsorId: number;
}
interface ad {
    id: 0;
    name: string;
    zone: string;
    id_sponsor: number;
    img: string;
    url: string;
}

export const zones = [
    { label: "Comunicado", value: 'notice-1' },
    { label: "Listado de Comunicados", value: 'notice' },
    { label: "Noticias", value: 'post' }
];

interface Ad {
    name: string
    zone: string
    id_sponsor: number
    id_notice?: number
    img: File | null
    url: string
}

const CreateAdvertisement: React.FC<CreateSponsorProps> = ({ buttonTitle, onCreate, sponsorId }) => {
    const [open, setOpen] = useState(false);
    const [useHTML, set_useHTML] = useState(false);
    const [advertisement, setAdvertisement] = useState<Ad>({
        name: "",
        zone: "",
        id_sponsor: sponsorId,
        img: null,
        url: "",
        id_notice: -1
    });
    const [notices, setNotices] = useState<any[]>([]);
    const [user, setUser] = useState<any>({});

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState<'error' | 'success'>('error');
    const [message, setMessage] = useState('');

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (open) {
            set_useHTML(false);
            const localStorageUser = localStorage.getItem("user")
            if (localStorageUser) {
                const userData = JSON.parse(localStorageUser)
                setUser(userData);
            }
        }
    }, [open])

    const handleOpen = () => {
        setOpen(true);
        fetchUsers()
    };

    const handleClose = () => {
        setOpen(false);
    };



    const fetchUsers = async () => {
        const response = await api.post(
            `/v1/notice/list`
        );
        setNotices(response.data.notices);
    }

    const handleSave = async () => {
        try {

            let zoneFinal = advertisement.zone
            let idNoticeFinal = advertisement.id_notice
            // notice-1 is for not selected
            if (advertisement.zone == "notice-1") {
                zoneFinal = "notice"
                idNoticeFinal = -1
            }

            const data = { ...advertisement, zone: zoneFinal, id_notice: idNoticeFinal }

            if (data.id_notice === -1) {
                delete data.id_notice
            }

            const response = await api.post('/v1/ad/create', data);
            await handleFile(response.data.ad.id)
            setAdvertisement({
                name: "",
                zone: "",
                id_sponsor: sponsorId,
                img: null,
                url: "",
                id_notice: -1
            })
            onCreate()
            handleClose();
            // Display success message in the Snackbar
            setOpenSnackbar(true);
            setSeverity('success');
            setMessage('Anuncio creado exitosamente');
        } catch (error) {
            console.error('Failed to create article:', error);
            setOpenSnackbar(true);
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
        }
    };

    const [file, set_file] = useState<any>(null)

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        set_file(file)
    };


    const handleFile = async (sponsorId: number) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            await api.post('/v1/file/create/ad/' + sponsorId, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
        } catch (error) {
            console.error('Failed to create sponsor:', error);
        }
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ color: "white" }}
            >
                {buttonTitle}
                <AddOutlined style={{ marginLeft: "10px" }} />
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Card sx={{ width: 700, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                    <Typography variant="h6" gutterBottom>
                        {buttonTitle}
                    </Typography>
                    <Divider />
                    <Grid >
                        <form>
                            <Grid>
                                <TextField
                                    label="Nombre"
                                    value={advertisement.name}
                                    onChange={(e) =>
                                        setAdvertisement({ ...advertisement, name: e.target.value })
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid>
                                <FormControl fullWidth>
                                    <InputLabel>Zona</InputLabel>
                                    <Select
                                        label="Zona"
                                        value={advertisement.zone}
                                        onChange={(e) =>
                                            setAdvertisement({ ...advertisement, zone: e.target.value })
                                        }
                                        fullWidth
                                    >
                                        {zones.filter((zoneItem) => {
                                            if (user.user?.role === "admin") {
                                                return true
                                            } else {
                                                if (user.role?.ads_zones.includes(zoneItem.value)) {
                                                    return true
                                                } else if (user.role?.ads_zones.includes("notice") && zoneItem.value == "notice-1") {
                                                    return true
                                                } else {
                                                    return false
                                                }
                                            }
                                        }).map((zone, key) => (
                                            <MenuItem key={key} value={zone.value}>
                                                {zone.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {advertisement.zone == "notice-1" && <Grid
                                style={{ marginTop: "15px" }}>
                                <Autocomplete
                                    disablePortal
                                    options={notices.map((notice) => { return { label: notice.title, value: notice.id } })}
                                    renderInput={(params) => <TextField {...params} label="Comunicado" required />}
                                    onChange={(e, n) => {
                                        setAdvertisement({ ...advertisement, id_notice: n?.value })
                                    }}
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <Button>
                                    <input type="file" onChange={handleFileChange} />
                                </Button>
                            </Grid>

                            <Grid>
                                <TextField
                                    label="URL"
                                    value={advertisement.url}
                                    onChange={(e) =>
                                        setAdvertisement({ ...advertisement, url: e.target.value })
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </form>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                            Cancelar
                        </Button>
                        <Button
                            sx={{ color: "white" }}
                            size="small" variant="contained" color="primary"
                            onClick={handleSave}>
                            Crear
                        </Button>
                    </Box>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleSnackbarClose} >
                        <Alert severity={severity}>{message}</Alert>
                    </Snackbar>
                </Card>
            </Modal>

        </div>
    );
};

export default CreateAdvertisement;
