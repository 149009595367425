import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import api from '../../services/api';
import MuiAlert from '@mui/material/Alert';
import AndroidStoreImage from "../../assets/img/androidstore.png"
import IosStoreImage from "../../assets/img/iosstore.png"
import { errorMessageFromBackend } from '../../utils/errorMessageFromBackend';

export default function Validate() {
    const { token } = useParams(); // Get the token from the URL params
    const navigate = useNavigate(); // Use history to navigate after resetting the password
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [showGracias, setShowGracias] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const password = data.get('password');
            const passwordConfirm = data.get('passwordConfirm');

            // Validate password and password confirmation
            if (!password || !passwordConfirm) {
                setError('Por favor ingresa una nueva contraseña y confírmala.');
                return;
            }

            if (password !== passwordConfirm) {
                setError('Las contraseñas no coinciden.');
                return;
            }

            // Send password reset request
            await api.post('/v1/user/verify', {
                token,
                password,
            });

            // Open dialog on success
            setDialogOpen(true);
            setError('');
        } catch (error) {
            // Handle error case, e.g., display an error message
            setError(errorMessageFromBackend(error));
        }
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setShowGracias(true);

    };

    return (
        <>
            <Snackbar
                open={error ? true : false}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setError("")} >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => setError("")}
                    severity={"error"}>
                    {error}
                </MuiAlert>
            </Snackbar>
            <Typography component="h1" variant="h5">
                Validar usuario
            </Typography>
            {showGracias ? (
                <Typography component="h2" variant="h6" sx={{ mt: 2 }}>
                    ¡Instala la app de la asociación si aún no la tienes!
                    <br />
                    <br />
                    <a href="https://play.google.com/store/apps/details?id=es.aotec.app">
                        <img width={200} src={AndroidStoreImage} />
                    </a>
                    <br />
                    <a href="https://apps.apple.com/us/app/aotec/id6451088266">
                        <img width={202} src={IosStoreImage} />
                    </a>

                </Typography>
            ) : (
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Nueva Contraseña"
                        name="password"
                        autoComplete="password"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="passwordConfirm"
                        label="Confirmar Nueva Contraseña"
                        name="passwordConfirm"
                        autoComplete="password"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Validar usuario
                    </Button>

                    <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="alert-dialog-title">
                        <DialogTitle>
                            <span style={{ fontSize: '14px' }}>
                                Tu cuenta se ha validado correctamente.
                            </span>
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={closeDialog} autoFocus>
                                Entendido
                            </Button>
                        </DialogActions>
                    </Dialog>


                </Box>
            )}
        </>
    );
}
