import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AdUnits, AdUnitsOutlined, Adb, Addchart, AutoAwesome, BookmarkOutlined, BrandingWatermark, ChecklistRtl, ChecklistRtlOutlined, HomeMax, HomeOutlined, Logout, MergeType, NewspaperOutlined, NewspaperRounded, PersonOutlineOutlined, StoreOutlined, TypeSpecimen } from '@mui/icons-material';

import Logo from "../../assets/img/whitelogo.png"
import Mosaico from "../../assets/img/mosaico.png"
import SponsorType from '../../pages/SponsorType/SponsorType';
import { TypeFormatFlags } from 'typescript';
import { sponsorTypeList } from '../../pages/SponsorType/CreateSponsorType';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    title?: string;
    children?: any
}


export default function Home(props: Props) {
    const { window, title, children } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [userDataStored, setUser] = React.useState<any>({ user: {}, role: {} });
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const navigate = useNavigate()
    React.useEffect(() => {
        const fetchCurrentAccount = async () => {
            const localStorageUser = localStorage.getItem("user")
            if (localStorageUser) {
                const userData = JSON.parse(localStorageUser)

                const response = await api.get('/v1/user/' + userData.user.id, {
                });

                const { user, role } = response.data;

                if (sponsorTypeList.includes(user.role)) {
                    if (role.permissions.includes("accessLogin")) {
                        if (role.access_login !== "panel" && role.access_login !== "all") {
                            localStorage.removeItem("token")
                            localStorage.removeItem("user")
                            navigate('/logout');
                        }
                    } else {
                        localStorage.removeItem("token")
                        localStorage.removeItem("user")
                        navigate('/logout');
                    }
                }

                localStorage.setItem('user', JSON.stringify({ user, role }));

                setUser({ user, role });
                setLoaded(true)
            }
        }

        fetchCurrentAccount();
    }, [])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    interface MenuItem {
        title: string
        icon: React.ReactElement,
        href: string,
        onClick?: () => void
        role?: string[]
        permissions?: string
    }
    const logout = () => {
        localStorage.removeItem('token')
    }
    const menuItems: MenuItem[] = [
        { title: "Inicio", icon: <HomeOutlined />, href: "/home", role: ["admin"], permissions: '' },
        // { title: "Perfil", icon: <PersonOutlineOutlined />, href: "/profile", role: sponsorTypeList, permissions: 'profileSponsor' },
        { title: "Comunicados", icon: <BookmarkOutlined />, href: "/releases", role: ["admin", ...sponsorTypeList], permissions: 'sendNotices' },
        { title: "Marketplace", icon: <StoreOutlined />, href: "/marketplace", role: ["admin"], permissions: '' },
        { title: "Usuarios", icon: <PersonOutlineOutlined />, href: "/users", role: ["admin"], permissions: '' },
        { title: "Patrocinadores", icon: <AdUnitsOutlined />, href: "/patrocinadores", role: ["admin", ...sponsorTypeList], permissions: 'profileSponsor' },
        { title: "Noticias", icon: <NewspaperRounded />, href: "/news", role: ["admin"], permissions: '' },
        { title: "Tipos de Patrocinadores", icon: <ChecklistRtlOutlined />, href: "/tipos-patrocinadores", role: ["admin"], permissions: '' },
        {
            title: "Salir", icon: <Logout />, href: "/logout", onClick: () => {
                localStorage.removeItem("token")
            },
        }
    ]

    const drawer = (
        <Box
            sx={{
                backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.primary.main : t.palette.primary.dark,
                backgroundImage: "url(" + Mosaico + ")",
                backgroundPosition: "bottom",
                backgroundSize: "300% auto",
                backgroundRepeat: "no-repeat",
                height: "100%"
            }}>

            <img src={Logo} width="150px" />

            <List>
                {loaded && menuItems.filter((menuItem: any) => {
                    if (userDataStored?.user?.role === "admin") {
                        return true
                    } else if (!menuItem.role) {
                        return true
                    } else if (menuItem.role.includes(userDataStored?.user?.role)) {
                        if (userDataStored?.role?.permissions?.includes(menuItem.permissions as never)) {
                            return true
                        } else {
                            return false
                        }
                    }
                }).map((item, index) => (
                    <ListItem
                        onClick={item.onClick ? item.onClick : () => { }}
                        key={"menu-item-" + index}
                        disablePadding
                    >
                        <ListItemButton href={item.href}
                            sx={{
                                background: location.href.includes(item.href) ? "rgba(255,255,255,.3)" : "",
                                color: location.href.includes(item.href) ? "#333" : ""
                            }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    display: { xs: 'block', sm: 'none' },
                }}

            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                            mr: 2,
                            display: { sm: 'none' }
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: drawerWidth,
                            backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.primary.main : t.palette.primary.dark,
                            color: "white"
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: drawerWidth,
                            backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.primary.main : t.palette.primary.dark,
                            color: "white"

                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >

                <Box
                    sx={{
                        paddingX: "10%"
                    }}
                >
                    <Toolbar
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                        }}
                    />
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                        }}>
                        <h2>{title}</h2>
                    </Box>
                    {children}
                </Box>
            </Box>
        </Box >
    );
}