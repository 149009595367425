import React, { useState } from "react";
import api from "../../services/api";
import { Box, Button, Snackbar } from "@mui/material";
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { Sync } from "@mui/icons-material";

const SyncNews = ({ refreshNews }: { refreshNews: () => void }) => {
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = React.useState<string>('');
    // snack bar alert
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [severity, setSeverity] = useState<AlertColor>('success');

    // snackbar handle
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    const handleSyncNews = async () => {
        try {
            setLoading(true)
            await api.get("v1/task/import-post/wordpress");
            await refreshNews()
            setMessage("Actualización completada");
            setOpenSnackbar(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
            setLoading(false)
        } catch (error) {
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setOpenSnackbar(true);
            setSeverity('error');
            console.error("Failed to sync users", error);
        }
    };

    return (
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose} >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}>
                    {message}
                </MuiAlert>
            </Snackbar>
            <Button
                variant="contained"
                onClick={handleSyncNews}
                disabled={loading}
                sx={{
                    color: "white"
                }}
            >
                Sincronizar
                <Sync style={{ marginLeft: "10px" }} className={loading ? "rotate-animation" : ""} />
            </Button>
        </Box>
    );
};

export default SyncNews;
