import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Divider,
  Card,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../services/api";
import { zones } from "./CreateAdvertisement";


interface ad {
  id: 0;
  name: string;
  zone: string;
  img: string;
  url: string;
  id_notice: number
}
interface EditArticleProps {
  buttonTitle: string;
  article: ad;
  onUpdate: () => void;
  openSnackbar: (value: boolean) => void;
  setMessage: (value: string) => void;
  setSeverity: any
}


const EditAdvertisement: React.FC<EditArticleProps> = ({
  buttonTitle,
  article,
  onUpdate,
  openSnackbar,
  setMessage,
  setSeverity,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [content, setContent] = useState<any>("");
  const [logs, setLogs] = useState<string[]>([]);
  const [date, setDate] = useState<Date | null | string | undefined>();
  const [useHTML, set_useHTML] = useState(false);
  const [notices, setNotices] = useState<any[]>([]);
  const [user, setUser] = useState<any>({});

  const [advertisement, setAdvertisement] = useState({
    id: 0,
    name: "",
    zone: "",
    img: "",
    url: "",
    id_notice: -1
  });

  useEffect(() => {
    const newAd = { ...article };
    const getZone = () => {
      if (newAd.zone === "notice" && newAd.id_notice) {
        return "notice-1"
      } else {
        return newAd.zone
      }
    }
    setAdvertisement({
      id: newAd.id,
      name: newAd.name,
      zone: getZone(),
      img: newAd.img,
      url: newAd.url,
      id_notice: newAd.id_notice,

    });
  }, [article]);

  const handleOpen = () => {
    setOpen(true);
    fetchNotices()
  };

  const handleClose = () => {
    setOpen(false);
  };

  const titleInputRef = useRef<HTMLInputElement>(null);
  const subtitleInputRef = useRef<HTMLInputElement>(null);
  const quillRef = useRef<ReactQuill | null>(null);

  const fetchNotices = async () => {
    const response = await api.post(
      `/v1/notice/list`
    );
    setNotices(response.data.notices);
  }

  useEffect(() => {
    if (open) {
      if (titleInputRef.current) {
        titleInputRef.current.value = title;
      }
      if (subtitleInputRef.current) {
        subtitleInputRef.current.value = subtitle;
      }
      if (quillRef.current) {
        quillRef.current.getEditor().setContents(content);
      }

      const localStorageUser = localStorage.getItem("user")
      if (localStorageUser) {
        const userData = JSON.parse(localStorageUser)
        setUser(userData);
      }

    }
  }, [title, content, open]);

  useEffect(() => {
    if (open) {
      set_useHTML(false);
    }
  }, [open]);

  const buildDefault = () => {

    const findTitle = notices.find(notice => {
      return notice.id == advertisement.id_notice
    })?.title

    if (findTitle) {
      const data = {
        label: findTitle,
        value: advertisement.id_notice
      }
      return data
    }

  }

  const handleSave = async () => {
    try {

      let zoneFinal = advertisement.zone
      let idNoticeFinal = advertisement.id_notice
      if (advertisement.zone == "notice-1") {
        zoneFinal = "notice"
        idNoticeFinal = -1
      }

      await api.put(
        `/v1/ad/edit/${advertisement.id}`,
        { ...advertisement, zone: zoneFinal, id_notice: idNoticeFinal }
      );
      await handleFile(advertisement.id)
      setMessage("Se han guardado los cambios");
      openSnackbar(true);
      handleClose();
      onUpdate();
    } catch (error) {
      console.error("Failed to edit anuncio:", error);
      setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

      openSnackbar(true);
    }
  };

  const [file, set_file] = useState<any>(null)

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    set_file(file)
  };


  const handleFile = async (sponsorId: number) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      await api.post('/v1/file/create/ad/' + sponsorId, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    } catch (error) {
      console.error('Failed to create sponsor:', error);
    }
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <EditIcon color="primary" />
      </IconButton>
      <Modal open={open} onClose={handleClose} disableEnforceFocus>
        <Card
          sx={{
            width: 850,
            p: 2,
            mt: 10,
            mx: "auto",
            bgcolor: "background.paper",
          }}
        >
          <Box >
          </Box>
          <Typography variant="h6" >
            {buttonTitle}
          </Typography>
          <Divider />
          <form>
            <TextField
              required
              label="Nombre"
              value={advertisement.name}
              onChange={(e) =>
                setAdvertisement({ ...advertisement, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <Grid>
              <FormControl fullWidth>
                <InputLabel>Zona</InputLabel>
                <Select
                  label="Zona"
                  value={advertisement.zone}
                  onChange={(e) =>
                    setAdvertisement({ ...advertisement, zone: e.target.value })
                  }
                  fullWidth
                >
                  {zones.filter((zoneItem) => {
                    if (user.user?.role === "admin") {
                      return true
                    } else {
                      if (user.role?.ads_zones.includes(zoneItem.value)) {
                        return true
                      } else if (user.role?.ads_zones.includes("notice") && zoneItem.value == "notice-1") {
                        return true
                      } else {
                        return false
                      }
                    }
                  }).map((zone, key) => (
                    <MenuItem key={key} value={zone.value}>
                      {zone.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {advertisement.zone == "notice-1" && notices.length > 0 && <Grid
              style={{ marginTop: "15px" }}>
              <Autocomplete
                disablePortal
                options={notices.map((notice) => { return { label: notice.title, value: notice.id } })}
                renderInput={(params) => <TextField {...params} label="Comunicado" required />}
                defaultValue={buildDefault()}
                onChange={(e, n) => {
                  setAdvertisement({ ...advertisement, id_notice: n?.value })
                }}
              />
            </Grid>}
            <Grid item xs={12}>
              <Button>
                <input type="file" onChange={handleFileChange} />
              </Button>
            </Grid>
            <TextField
              required
              label="URL"
              value={advertisement.url}
              onChange={(e) =>
                setAdvertisement({ ...advertisement, url: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </form>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleClose}
              sx={{ mr: 2 }}
            >
              Cancelar
            </Button>
            <Button
              sx={{ color: "white" }}
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              Actualizar
            </Button>
          </Box>
        </Card>
      </Modal>
    </div>
  );
};

export default EditAdvertisement;
