import React, { useEffect, useState } from "react"
import { Avatar, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import OrderBy from "../../components/Orderby/OrderBy"
import { CheckOutlined, Delete, MarkEmailUnreadOutlined } from "@mui/icons-material"
import EditIcon from '@mui/icons-material/Edit';
import { formatDateWithHour } from "../../utils/dateFormat";
import { getSponsorTypeName } from "../SponsorType/CreateSponsorType";

interface UsersTableProps {
    handleCheckAll: any,
    clearSortEmit: any,
    sortColumn: any,
    sortType: any,
    handleSortClick: any,
    filteredRows: any,
    rows: any,
    multiple: any,
    roleNames: any,
    handleCheckMultiple: any,
    handleEdit: any,
    handleSendValidation: any
    fetchUsers: any,
    handleDelete: any
}


const UsersTable = ({
    handleCheckAll,
    clearSortEmit,
    sortColumn,
    sortType,
    handleSortClick,
    filteredRows,
    rows,
    multiple,
    roleNames,
    handleCheckMultiple,
    handleEdit,
    handleDelete,
    handleSendValidation,
    fetchUsers
}: UsersTableProps) => {

    const [searchInput, setSearchInput] = useState(""); // State variable to track search input
    const [searchTimeout, setSearchTimeout] = useState<any>(null); // State variable to track the timeout


    useEffect(() => {
        // Clear previous timeout when the search input changes
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set a new timeout to send the API call after 3 seconds
        const newTimeout = setTimeout(() => {
            // Here, you can make the API call to the search API using the searchInput value
            fetchUsers(searchInput)
        }, 1000);

        setSearchTimeout(newTimeout); // Store the timeout in state

        // Cleanup function to clear the timeout when component unmounts or search input changes
        return () => {
            if (newTimeout) {
                clearTimeout(newTimeout);
            }
        };
    }, [searchInput]);

    const getRoleName = (role: string) => {

        switch (role) {
            case "member":
                return "Miembro"
            case "admin":
                return "Administrador"
            default:
                return getSponsorTypeName(role)
        }
    }

    return <>
        <TextField
            fullWidth
            label="Buscar"
            style={{ marginTop: "5px" }}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
        />
        <TableContainer>

            <Table size='small' className='table-center'>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                style={{ width: "5px", height: "5px" }}
                                onChange={(e) => handleCheckAll(e.target.checked)} />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <OrderBy
                                label="Rol"
                                sortLabel="role"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Nombre"
                                sortLabel="first_name"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Apellido"
                                sortLabel="last_name"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Empresa"
                                sortLabel="company_name"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Estado"
                                sortLabel="status"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Validado"
                                sortLabel="verified"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Creado"
                                sortLabel="created_at"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Validado"
                                sortLabel="validated_at"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell>

                            <OrderBy
                                label="Modificado"
                                sortLabel="updatedAt"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />

                        </TableCell>
                        <TableCell>
                            <OrderBy
                                label="Último acceso"
                                sortLabel="last_access"
                                clearSortEmit={clearSortEmit}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSort={handleSortClick}
                            />
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(filteredRows.length > 0 ? filteredRows : rows).map(
                        (row: any) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <Checkbox
                                        style={{ width: "5px", height: "5px" }}
                                        value={multiple.includes(row.id)} checked={multiple.includes(row.id)} onChange={(e) => handleCheckMultiple(e, row.id)} />
                                </TableCell>
                                <TableCell>
                                    <Avatar alt={row.first_name} src={row.picture_url} />
                                </TableCell>
                                <TableCell>{getRoleName(row.role)}</TableCell>
                                <TableCell>{row.first_name}</TableCell>
                                <TableCell>{row.last_name}</TableCell>
                                <TableCell>{row.company_name}</TableCell>
                                <TableCell>{row.status ? <CheckOutlined /> : ""}</TableCell>
                                <TableCell>{row.verified ? <CheckOutlined /> : ""}</TableCell>
                                <TableCell>
                                    <div style={{ width: "80px" }}>
                                        {formatDateWithHour(row.created_at)}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div style={{ width: "80px" }}>
                                        {row.validated_at ? formatDateWithHour(row.validated_at) : ""}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div style={{ width: "80px" }}>
                                        {formatDateWithHour(row.updatedAt)}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div style={{ width: "80px" }}>
                                        {formatDateWithHour(row.last_access)}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {multiple.length === 0 && <div style={{ display: "flex" }}>
                                        <IconButton
                                            onClick={() => handleEdit(row.id)}
                                            size="small"
                                        >
                                            <EditIcon color='primary' />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete(row.id)}
                                            size="small"
                                        >
                                            <Delete color='primary' />
                                        </IconButton>
                                        {(!row.verified && row.status) &&
                                            <IconButton
                                                size="small"
                                                onClick={() => handleSendValidation(row.id)}
                                            >
                                                <MarkEmailUnreadOutlined color='primary' />
                                            </IconButton>
                                        }
                                    </div>}


                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default UsersTable