import React, { useState } from 'react';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import "./OrderBy.css"

interface OrderByProps {
    label: string;
    clearSortEmit: () => void;
    sortColumn: string;
    sortType: string;
    onSort: (column: string, type: string) => void;
    sortLabel: string
}

const OrderBy = ({ label, clearSortEmit, sortColumn, sortType, onSort, sortLabel }: OrderByProps) => {
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

    const handleSortClick = () => {
        const newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
        setSortDirection(newSortDirection);
        onSort(sortLabel, newSortDirection);
    };

    return (
        <div className='sort-table' onClick={handleSortClick}>
            {label}
            {sortColumn === sortLabel && (
                sortType === 'ASC' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />
            )}
        </div>
    );
};

export default OrderBy;
