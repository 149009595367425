import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField, Divider, Grid, Card, Select, MenuItem, FormControl, InputLabel, Autocomplete, Checkbox, IconButton, Snackbar, Alert } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import api from '../../services/api';
import { AddOutlined, Edit } from '@mui/icons-material';

interface CreateSponsorProps {
    buttonTitle?: string;
    onCreate: () => void;
    isEdit?: boolean,
    typeToEdit?: any
}

export const zones = [
    { label: "Comunicado", value: 'notice-1', },
    { label: "Listado de Comunicados", value: 'notice', },
    { label: "Noticias", value: 'post', }
];

export const sponsorTypeList = ['sponsor', 'sponsor_silver', 'sponsor_gold', 'sponsor_platinum'];

export const sponsorTypeNamesList: { [key: string]: string } = {
    sponsor: "Patrocinador",
    sponsor_silver: "Silver",
    sponsor_gold: "Gold",
    sponsor_platinum: "Platinum"
}

export const getSponsorTypeName = (type: string) => {
    return sponsorTypeNamesList[type];
}

const permissionsList = [
    'accessLogin',
    'listSponsors',
    'profileSponsor',
    'files',
    'ads',
    'sendNotices'
]

export const permissionsNamesList: { [key: string]: string } = {
    accessLogin: 'Inicio de sesión',
    listSponsors: 'Lista de patrocinadores',
    profileSponsor: 'Perfil de patrocinador',
    files: 'Archivos',
    ads: 'Anuncios',
    sendNotices: 'Enviar Comunicados'
}

const getPermissionsName = (type: string) => {
    return permissionsNamesList[type];
}

const accessLoginList = [
    'accessLogin',
    'listSponsors',
    'profileSponsor',
    'files',
    'ads',
    'sendNotices'
]

export const accessLoginNamesList: { [key: string]: string } = {
    accessLogin: 'Inicio de sesión',
    listSponsors: 'Lista de patrocinadores',
    profileSponsor: 'Perfil de patrocinador',
    files: 'Archivos',
    ads: 'Anuncios',
    sendNotices: 'Enviar Comunicados'
}

const getAccessLoginName = (type: string) => {
    return accessLoginNamesList[type];
}

const CreateSponsorType: React.FC<CreateSponsorProps> = ({ buttonTitle, onCreate, isEdit, typeToEdit }) => {
    const [open, setOpen] = useState(false);
    const [useHTML, set_useHTML] = useState(false);
    const [sponsorType, set_sponsorType] = useState<any>({
        permissions: [],
        ads_zones: [],
        period_publications: "",
        quantity_publications: 0,
        schedule_same_day: false,
        name: "",
        description: "",
        clientify_tag: null,
        type: "",
        access_login: ""
    });

    const [notices, setNotices] = useState<any[]>([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState<'error' | 'success'>('error');
    const [message, setMessage] = useState('');

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (open) {
            set_useHTML(false)
        }
    }, [open])

    const handleOpen = () => {
        setOpen(true);
        fetchUsers()
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        if (isEdit) {
            set_sponsorType({ ...typeToEdit })
        }

    }, [isEdit, typeToEdit])



    const fetchUsers = async () => {
        const response = await api.post(
            `/v1/notice/list`
        );
        setNotices(response.data.notices);
    }

    const handleSave = async () => {
        try {

            const data = sponsorType
            if (!data.access_login) {
                delete data.access_login
            }

            if (!Array.isArray(data.ads_zones)) {
                data.ads_zones = []
            }

            if (isEdit) {
                await api.put('v1/role/edit/' + data.id, data);

            } else {
                await api.post('v1/role/create', data);

            }
            set_sponsorType({
                permissions: [],
                ads_zones: [],
                period_publications: "",
                quantity_publications: 0,
                schedule_same_day: false,
                name: "",
                description: "",
                clientify_tag: null,
                type: "",
                access_login: ""
            })
            onCreate()
            handleClose();
            // Display success message in the Snackbar
            setOpenSnackbar(true);
            setSeverity('success');
            setMessage('Tipo creado exitosamente');
        } catch (error) {
            console.error('Failed to create advertisement:', error);
            setOpenSnackbar(true);
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
        }
    };

    const [file, set_file] = useState<any>(null)

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        set_file(file)
    };


    const handleFile = async (sponsorId: number) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            await api.post('/v1/file/create/ad/' + sponsorId, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
        } catch (error) {
            console.error('Failed to create sponsor:', error);
        }
    };

    return (
        <div>

            {isEdit ? <IconButton
                onClick={handleOpen}

            >
                <Edit color='primary' />
            </IconButton> : <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ color: "white" }}
            >{buttonTitle}
                <AddOutlined style={{ marginLeft: "10px" }} />
            </Button>
            }

            <Modal open={open} onClose={handleClose}>
                <Card sx={{ width: 700, maxHeight: "80vh", overflow: "auto", p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                    <Typography variant="h6" gutterBottom>
                        {buttonTitle}
                    </Typography>
                    <Divider />
                    <Grid >
                        <form
                            onSubmit={handleSave}
                        >
                            <TextField
                                label="Nombre"
                                id="name"
                                fullWidth
                                margin="normal"
                                value={sponsorType.name}
                                onChange={(e) =>
                                    set_sponsorType({ ...sponsorType, name: e.target.value })
                                }
                            />

                            <TextField
                                label="Descripción"
                                id="description"
                                fullWidth
                                margin="normal"
                                value={sponsorType.description}
                                onChange={(e) =>
                                    set_sponsorType({ ...sponsorType, description: e.target.value })
                                }
                            />

                            <FormControl fullWidth>
                                <InputLabel id="type-select">Tipo</InputLabel>
                                <Select
                                    labelId="type-select"
                                    id="type-select"
                                    value={sponsorType.type}
                                    onChange={(e) =>
                                        set_sponsorType({ ...sponsorType, type: e.target.value })
                                    }
                                    label="Tipo"
                                >
                                    {
                                        sponsorTypeList.map((sponsorTypeItem, index) => <MenuItem
                                            key={"_sponsorList" + index} value={sponsorTypeItem}>
                                            {getSponsorTypeName(sponsorTypeItem)}
                                        </MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            <br />
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="permissions-label">Permisos</InputLabel>
                                <Select
                                    labelId="permissions-label"
                                    id="permissions-select"
                                    label="Permisos"
                                    multiple
                                    value={sponsorType.permissions}
                                    onChange={(e) =>
                                        set_sponsorType({ ...sponsorType, permissions: e.target.value })
                                    }
                                >
                                    {
                                        permissionsList.map((permissionsItem, index) => <MenuItem
                                            key={"_sponsorList" + index} value={permissionsItem}>
                                            {getPermissionsName(permissionsItem)}
                                        </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            {sponsorType.permissions.includes("accessLogin") && <><FormControl fullWidth>
                                <InputLabel id="access_login-label">Accesos</InputLabel>
                                <Select
                                    labelId="access_login-label"
                                    id="access_login"
                                    label="Accesos"
                                    value={sponsorType.access_login}
                                    onChange={(e) =>
                                        set_sponsorType({ ...sponsorType, access_login: e.target.value })
                                    }
                                >
                                    <MenuItem value="app">App</MenuItem>
                                    <MenuItem value="panel">Panel</MenuItem>
                                    <MenuItem value="all">Todos</MenuItem>
                                </Select>
                            </FormControl>
                                <br />
                                <br />
                            </>}

                            {sponsorType.permissions.includes("ads") && <><FormControl fullWidth>
                                <InputLabel id="ads_zones-label">Zonas de Anuncios</InputLabel>
                                <Select
                                    labelId="ads_zones-label"
                                    id="ads_zones"
                                    multiple
                                    label="Zonas de Anuncios"
                                    value={sponsorType.ads_zones}
                                    onChange={(e) =>
                                        set_sponsorType({ ...sponsorType, ads_zones: e.target.value })
                                    }
                                >
                                    <MenuItem value="notice">Comunicados</MenuItem>
                                    <MenuItem value="post">Noticias</MenuItem>
                                </Select>
                            </FormControl>
                                <br />
                                <br />
                            </>}
                            {sponsorType.permissions.includes("sendNotices") && <>
                                <FormControl fullWidth>
                                    <InputLabel id="period_publications-label">Periodos de Publicaciones</InputLabel>
                                    <Select
                                        labelId="period_publications-label"
                                        id="period_publications-select"
                                        label="Periodos de Publicaciones"
                                        value={sponsorType.period_publications}
                                        onChange={(e) =>
                                            set_sponsorType({ ...sponsorType, period_publications: e.target.value })
                                        }

                                    >
                                        <MenuItem value='monthly'>Mensual</MenuItem>
                                        <MenuItem value='quarterly'>Trimestral</MenuItem>
                                        <MenuItem value='yearly'>Anual</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    label="Cantidad de Publicaciones"
                                    id="quantity_publications"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    value={sponsorType.quantity_publications}
                                    onChange={(e) =>
                                        set_sponsorType({ ...sponsorType, quantity_publications: e.target.value })
                                    }
                                />

                                <label>
                                    <Checkbox
                                        checked={sponsorType.schedule_same_day}
                                        onChange={(e) =>
                                            set_sponsorType({ ...sponsorType, schedule_same_day: e.target.checked })
                                        }
                                        style={{ width: "5px", height: "5px" }}
                                    /> Agendar para el mismo día
                                </label></>}


                        </form>

                    </Grid>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleSnackbarClose} >
                        <Alert severity={severity}>{message}</Alert>
                    </Snackbar>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                            Cancelar
                        </Button>
                        <Button
                            sx={{ color: "white" }}
                            size="small" variant="contained" color="primary"
                            onClick={handleSave}>
                            {isEdit ? "Editar" : "Crear"}
                        </Button>
                    </Box>
                </Card>
            </Modal >
        </div >
    );
};

export default CreateSponsorType;
