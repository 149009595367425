import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, Grid, Card, Badge, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar, LocalizationProvider, PickersDay, StaticDateTimePicker, TimePicker, esES } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import api from '../../services/api';
interface ScheduleFormProps {
    onSelectDate?: (date: Date | null) => void;
    disabled: boolean;
}

const ScheduleForm: React.FC<ScheduleFormProps> = ({ onSelectDate, disabled }) => {
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [highlightedDays, set_highlightedDays] = useState([])
    const [user, setUser] = useState<any>({});
    useEffect(() => {

        const fetchNextPosts = async () => {
            const response = await api.post("/v1/notice/list", {
                startDate: new Date().setDate(new Date().getDate() - 1),
                allDate: true,
                category: "promotion_sponsor"
            })
            const usedDays: any = []
            response.data.notices.forEach((noticeItem: any) => {
                usedDays.push(new Date(noticeItem.send_at))
            })

            set_highlightedDays(usedDays)
        }

        const localStorageUser = localStorage.getItem("user")
        if (localStorageUser) {
            const userData = JSON.parse(localStorageUser)
            setUser(userData);
        }

        fetchNextPosts()

    }, [])
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const handleSave = () => {
        if (onSelectDate) {
            onSelectDate(selectedDate);
        }
        handleClose();
    };

    function ServerDay(props: any) {
        const { day, outsideCurrentMonth, ...other } = props;
        const currentDate = new Date(day.toString())
        const isSelected = !props.outsideCurrentMonth && highlightedDays.find((highlightedDaysItem: any) => {
            const usedDaysValidation = highlightedDaysItem.getFullYear() == currentDate.getFullYear() &&
                highlightedDaysItem.getMonth() == currentDate.getMonth() &&
                highlightedDaysItem.getDate() == currentDate.getDate()

            if (user.user.role === "admin") {
                return false
            } else {
                if (user.role.schedule_same_day) {
                    return false
                } else {
                    return usedDaysValidation
                }
            }
        });

        return (
            <Tooltip title={isSelected ? 'Día no disponible' : undefined} arrow>
                <Badge
                    key={props.day.toString()}
                    overlap="circular"
                    badgeContent={isSelected ? '❌' : undefined}
                >
                    <PickersDay disabled={isSelected ? true : false} {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
                </Badge>
            </Tooltip>
        );
    }


    return (
        <div>
            <Button onClick={handleOpen} size="small" variant="outlined" sx={{ mr: 2 }} disabled={disabled}>
                Programar envío
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Card sx={{ width: 600, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                    <Typography variant="h6" gutterBottom>
                        Programar envío
                    </Typography>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} >

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <StaticDateTimePicker
                                    defaultValue={selectedDate as Date}
                                    orientation="landscape"
                                    onChange={handleDateChange}
                                    onAccept={handleSave}
                                    onClose={handleClose}
                                    slots={{
                                        day: ServerDay,
                                    }}
                                    slotProps={{
                                        day: {
                                            highlightedDays,
                                        } as any,
                                    }}
                                    localeText={{ ...esES.components.MuiLocalizationProvider.defaultProps.localeText, okButtonLabel: "PROGRAMAR", toolbarTitle: "" }} />
                            </LocalizationProvider>
                            {/* 
                            <DatePicker
                                label="Fecha de publicación"
                                value={selectedDate}
                                onChange={handleDateChange}
                                format='DD/MM/YYYY - HH:mm'
                            /> */}
                        </Grid>
                    </Grid>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                            Cancelar
                        </Button>

                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            sx={{ color: "white" }}
                            disabled={highlightedDays.find((highlightedDaysItem: any) => {

                                const currentDayParsed = new Date(selectedDate?.toString() as string)

                                const usedDaysValidation = highlightedDaysItem.getFullYear() == currentDayParsed?.getFullYear?.() &&
                                    highlightedDaysItem.getMonth() == currentDayParsed?.getMonth?.() &&
                                    highlightedDaysItem.getDate() == currentDayParsed?.getDate?.()

                                if (user.user.role === "admin") {
                                    return false
                                } else {
                                    if (user.role.schedule_same_day) {
                                        return false
                                    } else {
                                        return usedDaysValidation
                                    }
                                }
                            }) ? true : false}
                        >
                            Programar
                        </Button>
                    </Box> */}
                </Card>
            </Modal>
        </div>
    );
};

export default ScheduleForm;
