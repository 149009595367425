import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Images
import Logo from "../../assets/img/whitelogo.png"
import Mosaico from "../../assets/img/mosaico.png"

export default function Auth({ children }: { children: any }) {


    React.useEffect(() => {
        document.querySelector("#auth-content")?.scrollIntoView()
    }, [])

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                sx={{
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.primary.main : t.palette.primary.dark,
                    backgroundImage: "url(" + Mosaico + ")",
                    backgroundPosition: "bottom",
                    backgroundSize: "100% auto",
                    backgroundRepeat: "no-repeat",
                    color: "white",
                    padding: "7%",
                    flexGrow: "1 !important",
                    maxWidth: "100% !important",
                    width: "100%"
                }}
            >
                <img src={Logo} width={"200px"} />
                <h1>Panel de Administración AOTEC</h1>
                <p style={{ marginBottom: "200px" }}>Herramienta Integral para la Gestión de Comunicaciones, Clasificados y Coordinación de Miembros de la Asociación AOTEC</p>

            </ Grid>
            <Grid item xs={12} md={5} component={Paper} elevation={6} square>
                <Box
                    id="auth-content"
                    sx={{
                        my: 8,
                        mx: 4,
                        padding: "5%",
                        paddingTop: "10%"
                    }}
                >
                    {children}

                </Box>
            </Grid>
        </Grid>
    );
}