
import React, { useState } from 'react';
import { IconButton, Tooltip, Popover, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import { MoreVert, Delete, MarkEmailUnreadOutlined } from '@mui/icons-material';

const Multiple = ({ multiple, handleDelete, handleSendEmail }:
    {
        multiple: any[],
        handleDelete?: () => void,
        handleSendEmail?: (id?: number) => void
    }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        // Perform delete operation here
        if (handleDelete) {
            handleDelete()
        }
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <>
            {multiple.length > 0 && (
                <>
                    <Tooltip title="Selección múltiple">
                        <IconButton onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <List>
                            {handleDelete && <ListItemButton onClick={handleDeleteClick}>
                                <ListItemIcon>
                                    <Delete />
                                </ListItemIcon>
                                <ListItemText primary="Eliminar" />
                            </ListItemButton>
                            }
                            {/* only for users crud */}
                            {handleSendEmail && <ListItemButton onClick={() => handleSendEmail()}>
                                <ListItemIcon>
                                    <MarkEmailUnreadOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Enviar Validación" />
                            </ListItemButton>
                            }
                        </List>
                    </Popover>
                </>
            )}
        </>
    );
};

export default Multiple;
