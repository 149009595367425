import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    Snackbar,
    Select,
    MenuItem,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateArticle from '../../components/CreateArticle/CreateArticle';
import EditArticle from '../../components/EditArticle/EditArticle';
import api from '../../services/api';
import { formatDate, formatDateWithHour } from '../../utils/dateFormat';
import { AnalyticsOutlined, Check, DonutLarge, DonutSmallOutlined, DonutSmallRounded, MoreVert } from '@mui/icons-material';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { ClockIcon } from '@mui/x-date-pickers';
import OrederBy from '../../components/Orderby/OrderBy';
import ReleasesAnalitics from '../../components/ReleasesAnalitics/ReleasesAnalitics';
import ReleaseMultiple from '../../components/Multiple';
import EditFiles from '../Sponsors/EditFiles';
import { sponsorTypeList } from '../SponsorType/CreateSponsorType';

export interface Notice {
    id: number;
    type: string;
    title: string;
    subtitle: string;
    content: string;
    notification: boolean;
    send_at: string;
    created_at: string;
    modified_at: string;
    createdAt: string;
    updatedAt: string;
    category: string;
    views: {
        total: number,
        totalNotRead: number,
        usersRead: any[]
    }
    created_by: {
        first_name: "",
        last_name: ""
    }
}

const Releases = () => {
    const [rows, setRows] = useState<Notice[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(-1);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });

    const [multiple, setMultiple] = useState<number[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const [clearSort, setclearSort] = useState(0);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [user, setUser] = useState<any>({});

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        const localStorageUser = localStorage.getItem("user")
        if (localStorageUser) {
            const userData = JSON.parse(localStorageUser)
            setUser(userData);
        }
    }, [])

    useEffect(() => {
        fetchNotices();
    }, [pagination.page, pagination.limit]);

    const fetchNotices = async () => {
        try {

            let orderQuery: any = {
                allDate: true
            }
            if (sortColumn && sortType) {
                orderQuery = {
                    order: [sortColumn, sortType]
                }
            }

            const localStorageUser = localStorage.getItem("user")
            if (localStorageUser) {
                const userData = JSON.parse(localStorageUser)
                if (sponsorTypeList.includes(userData.user.role)) {
                    orderQuery.created_by = userData.user.id
                }
            }

            const response = await api.post(`/v1/notice/list?limit=${pagination.limit}&page=${pagination.page}`, {
                ...orderQuery
            });
            const { notices, paginate } = response.data;
            setRows(notices);
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: response.data.paginate.pageCount,
            }));
        } catch (error) {
            console.error('Failed to fetch notices', error);
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleDelete = async () => {
        try {
            if (multiple.length > 0) {
                await Promise.all(
                    multiple.map(async (id) => {
                        await api.delete(`/v1/notice/del/${id}`);
                    })
                );
                setMultiple([])
            } else {
                await api.delete(`/v1/notice/del/${deleteItemId}`);
            }

            fetchNotices();
            setDeleteItemId(-1);
            closeDialog();
            setMessage('Comunicado eliminado satisfactoriamente');
            setSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");
            setSeverity('error');
            setOpenSnackbar(true);
            console.error('Failed to delete notice', error);
        }
    };

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const handlePaginationChange = (page: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: page,
        }));
    };

    const handleCheckMultiple = (e: any, id: number) => {
        let newMultiple = [...multiple];
        if (e.target.checked) {
            newMultiple.push(id);
        } else {
            const index = newMultiple.findIndex((idItem) => idItem === id);
            if (index !== -1) {
                newMultiple.splice(index, 1);
            }
        }
        setMultiple(newMultiple);
    };

    const handleCheckAll = (selectAll: boolean) => {
        let newSelected: number[] = [];
        if (selectAll) {
            rows.forEach((rowItem) => {
                newSelected.push(rowItem.id);
            });
        }
        setMultiple(newSelected);
    };

    const handleResultsPerPageChange = (event: any) => {
        const resultsPerPage = parseInt(event.target.value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: resultsPerPage,
        }));
        setSortColumn('');
        setSortType('');
    };

    const clearSortEmit = () => {
        setclearSort(Date.now())
    }

    const handleSortClick = (column: string, type: string) => {
        setSortColumn(column);
        setSortType(type);
    }

    useEffect(() => {
        fetchNotices()
    }, [sortColumn, sortType])

    const getCategoryName = (eventName: string) => {
        if (!eventName) {
            return ""
        }

        const categoryNames: { [key: string]: string } = {
            aotec: "AOTEC",
            formation: "Formación",
            event: "Eventos",
            bulletin: "Boletines",
            promotion_sponsor: "Patrocinadores"
        }

        return categoryNames[eventName]

    }

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: "inline-flex",
                        justifyContent: "end",
                        width: "100%"
                    }}>
                        <CreateArticle
                            buttonTitle="Crear"
                            onCreate={() => {
                                fetchNotices();
                            }}
                        />
                        <ReleaseMultiple
                            multiple={multiple}
                            handleDelete={handleDelete} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <Table size="small" className='table-center'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            style={{ width: "5px", height: "5px" }}
                                            onChange={(e) => handleCheckAll(e.target.checked)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            label="Título"
                                            sortLabel="title"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="notification"
                                            label="Notificación"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            label="Vísto"
                                            sortLabel="views"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            label="Categoría"
                                            sortLabel="category"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="created_by"
                                            label="Creado por"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="send_at"
                                            label="Envío"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="created_at"
                                            label="Creado"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <OrederBy
                                            sortLabel="updatedAt"
                                            label="Modificado"
                                            clearSortEmit={clearSortEmit}
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSort={handleSortClick}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <Checkbox
                                                style={{ width: "5px", height: "5px" }}
                                                value={multiple.includes(row.id)}
                                                checked={multiple.includes(row.id)}
                                                onChange={(e) => handleCheckMultiple(e, row.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell>
                                            {row.notification ? <Check /> : ""}
                                        </TableCell>
                                        <TableCell>
                                            {row.views.total}
                                        </TableCell>
                                        <TableCell>
                                            {getCategoryName(row.category)}
                                        </TableCell>
                                        <TableCell>
                                            {row.created_by?.first_name} {row.created_by?.last_name}
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {new Date(row.send_at) > new Date() ? <ClockIcon color='primary' style={{ marginRight: "10px" }} /> : ""}
                                                {formatDateWithHour(row.send_at)}
                                            </div>
                                        </TableCell>
                                        <TableCell>{formatDateWithHour(row.created_at)}</TableCell>
                                        <TableCell>{formatDateWithHour(row.updatedAt)}</TableCell>
                                        <TableCell>
                                            <div style={{ display: "inline-flex" }}>
                                                <ReleasesAnalitics
                                                    buttonTitle="Estadísticas"
                                                    total={row.views?.total}
                                                    totalNotRead={row.views?.totalNotRead}
                                                    usersRead={row.views?.usersRead}
                                                />
                                                {(user?.user?.role === "admin" || user?.role?.permissions?.includes("files")) && <EditFiles id={row.id} model="notice" />}

                                                {multiple.length == 0 &&
                                                    <>
                                                        <Tooltip title="Eliminar">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setDeleteItemId(row.id);
                                                                    openDialog();
                                                                }}
                                                            >
                                                                <DeleteIcon color='primary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {/* {new Date(row.send_at) > new Date() && <EditArticle
                                                            onUpdate={() => {
                                                                fetchNotices();
                                                            }}
                                                            buttonTitle="Editar Comunicado"
                                                            article={row}
                                                            openSnackbar={setOpenSnackbar}
                                                            setMessage={setMessage}
                                                        />} */}
                                                        <EditArticle
                                                            onUpdate={() => {
                                                                fetchNotices();
                                                            }}
                                                            buttonTitle="Editar Comunicado"
                                                            article={row}
                                                            openSnackbar={setOpenSnackbar}
                                                            setMessage={setMessage}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid >

            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={pagination.page === 1}
                        onClick={() => handlePaginationChange(pagination.page - 1)}
                    >
                        Anterior
                    </Button>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                        }}
                    >
                        Página {pagination.page} de {pagination.total}
                    </Box>
                    <Button
                        disabled={pagination.page === (pagination.total)}
                        onClick={() => handlePaginationChange(pagination.page + 1)}
                    >
                        Siguiente
                    </Button>
                    <Select
                        value={pagination.limit} // Valor inicial del número de resultados por página
                        onChange={handleResultsPerPageChange}
                        style={{ marginLeft: '8px' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Box>
            </Grid>

            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Realmente deseas eliminar este comunicado?
                </DialogTitle>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteItemId(-1);
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete();
                            closeDialog();
                        }}
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Releases;
