import React, { useState, useEffect } from 'react';
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Box, Button, Card } from '@mui/material';
import { formatDate, formatDateWithHour } from '../../utils/dateFormat';
import { Check } from '@mui/icons-material';
import api from '../../services/api';

export interface Post {
    id: number;
    title: string;
    content: string;
    created_at: string;
    views: {
        total: number
    }
}

interface Paginate {
    has_more: boolean;
    itemCount: number;
    pageCount: number;
}

const NewsTable = () => {
    const [rows, setRows] = useState<Post[]>([]);
    const [pagination, setPagination] = useState<Paginate>({
        has_more: false,
        itemCount: 0,
        pageCount: 1,
    });
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchPosts();
    }, [currentPage]);

    const fetchPosts = async () => {
        try {
            const response = await api.post(`/v1/post/list?limit=5&page=${currentPage}`);
            const { posts, paginate } = response.data;
            setRows(posts);
            setPagination({
                has_more: paginate.has_more,
                itemCount: paginate.itemCount,
                pageCount: paginate.pageCount,
            });
        } catch (error) {
            console.error('Failed to fetch posts', error);
        }
    };

    const goToPage = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <Card>
            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small" className='table-center'>
                        <TableHead
                            sx={{
                                backgroundColor: (t) => t.palette.primary.light,
                            }}>
                            <TableRow>
                                <TableCell>Título</TableCell>
                                <TableCell>Visto</TableCell>
                                <TableCell>Creado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell>{row.views.total}</TableCell>
                                    <TableCell>{formatDateWithHour(row.created_at)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button
                        disabled={currentPage === 1}
                        onClick={() => goToPage(currentPage - 1)}
                    >
                        Anterior
                    </Button>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px"
                    }}>
                        Página {currentPage} de {pagination.pageCount}
                    </Box>
                    <Button
                        disabled={!pagination.has_more}
                        onClick={() => goToPage(currentPage + 1)}
                    >
                        Siguiente
                    </Button>
                </Box>
            </Grid>
        </Card>
    );
};

export default NewsTable;
