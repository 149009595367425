import React, { useEffect, useState } from "react"
import EditSponsor from "../Sponsors/EditSponsor";
import api from "../../services/api";
import { Sponsor } from "../Sponsors/Sponsors";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertColor } from '@mui/material/Alert';

const Profile = () => {

    const [sponsor, set_sponsor] = useState<Sponsor>();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const fetchProfile = async () => {
        try {
            const localStorageUser = localStorage.getItem("user")

            if (localStorageUser) {
                const userData = JSON.parse(localStorageUser)
                const response = await api.post("v1/sponsor/list/", {
                    id_user: userData.id,
                    forceNotAllowList: true

                })
                set_sponsor(response.data.sponsor[0])
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProfile()
    }, [])

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return <>
        {sponsor?.name ? <>
            <EditSponsor
                onUpdate={() => {
                    // fetchNotices();
                }}
                hideModal={true}
                buttonTitle="Editar Patrocinador"
                article={sponsor}
                openSnackbar={setOpenSnackbar}
                setMessage={setMessage}
            // hideModal={true}
            /> </> : <>No se ha asigando ningún patrocinador</>}


        {/* {sponsor && <EditSponsor
            onUpdate={() => {
                // fetchNotices();
            }}
            buttonTitle="Editar Patrocinador"
            article={sponsor}
            openSnackbar={setOpenSnackbar}
            setMessage={setMessage}
            hideModal={true}
        />} */}

        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleSnackbarClose}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={severity}
            >
                {message}
            </MuiAlert>
        </Snackbar>

    </>

}

export default Profile