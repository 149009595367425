import React, { useState, useEffect } from "react";
import { Button, Grid, Icon } from "@mui/material";
import { FileOpenSharp } from "@mui/icons-material";
import "./FilesNames.css";

const FileNames = ({ setChanges, initialFiles }: {
    setChanges: (changes: { file?: File, name: string, id: number, url: string }[]) => void,
    initialFiles?: any[]
}) => {
    const [fileData, setFileData] = useState<any[]>([]);

    useEffect(() => {
        if (initialFiles) {
            setFileData(initialFiles);
            setChanges(initialFiles);
        }
    }, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            const newFileData = selectedFilesArray.map((file, index) => ({
                file: file,
                name: file.name,
                id: fileData.length + index + Date.now(),
                new: true,
                url: file.type.startsWith("image/") ? URL.createObjectURL(file) : ""
            }));
            setFileData([...fileData, ...newFileData]);
        }
    };

    const handleFileNameChange = (id: number, newName: string) => {
        if (newName !== undefined) {
            const updatedFileData = fileData.map(fileObj => {
                if (fileObj.id === id) {
                    return { ...fileObj, name: newName };
                }
                return fileObj;
            });
            setFileData(updatedFileData);
        }
    };

    const handleDelete = async (fileObj: any) => {
        if (fileObj.new) {
            const updatedFileData = [...fileData]
            updatedFileData.splice(updatedFileData.findIndex((item: any) => item.id === fileObj.id), 1)

            setFileData(updatedFileData)
        } else {
            let updatedFileData = [...fileData]
            updatedFileData = updatedFileData.map((item: any) => {
                if (item.id === fileObj.id) {
                    return { ...item, deleted: true }
                } else {
                    return item
                }
            })

            setFileData(updatedFileData)
        }
    }

    useEffect(() => {
        setChanges(fileData);
    }, [fileData])


    return (
        <div className="file-container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <label>Archivos</label>
                    <Button component="label">
                        Seleccionar archivos
                        <input
                            multiple
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                    </Button>
                </Grid>
                <Grid style={{ padding: "15px" }}>
                    {fileData.filter((i) => !i.deleted).map(fileObj => (
                        <div className="file-preview" key={fileObj.id}>
                            {fileObj.url ? (
                                <img
                                    src={fileObj.url}
                                    alt={`Preview ${fileObj.id}`}
                                    className="image-preview"
                                />
                            ) : (
                                <div className="file-icon">
                                    <FileOpenSharp style={{ width: "40px", height: "40px", color: "#4ea8c8" }} />
                                </div>
                            )}
                            <br />
                            <input
                                type="text"
                                value={fileObj.name}
                                placeholder="Enter a name"
                                className="file-name-input"
                                onChange={(e) => handleFileNameChange(fileObj.id, e.target.value)}
                            />
                            <br />
                            <Button onClick={() => handleDelete(fileObj)}>Eliminar</Button>
                        </div>
                    ))}
                </Grid>

            </Grid>
        </div>
    );
};

export default FileNames;
