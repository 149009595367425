import React, { useEffect, useState, useRef } from 'react';
import {
    Button, Modal, Box, Typography,
    Tooltip,
    TextField, IconButton, Divider, Grid,
    Card, Switch, FormControlLabel, Snackbar, CardContent, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody, Checkbox, TableSortLabel, TablePagination
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import api from '../../services/api';
import { Notice } from '../../pages/Releases/Releases';
import { DonutSmallRounded } from '@mui/icons-material';
import ApexCharts from 'apexcharts';

interface ReleasesAnaliticsProps {
    buttonTitle: string;
    total: number,
    totalNotRead: number,
    usersRead: any[]
}

const ReleasesAnalitics: React.FC<ReleasesAnaliticsProps> = ({
    buttonTitle,
    total = 0,
    totalNotRead = 0,
    usersRead,
}) => {
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState("");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc" | "">("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (open) {
            setChart();
        }
    }, [open]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setChart = () => {
        setTimeout(() => {
            const options = {
                series: [total, totalNotRead],
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: ["Leído", "No leído"],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            };
            const chart = new ApexCharts(
                document.querySelector("#chart"),
                options
            );

            chart.render();
        }, 100);
    };

    // Pagination
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = usersRead.slice(indexOfFirstRow, indexOfLastRow);

    // Sorting
    const handleSort = (field: string) => {
        if (field === sortField) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortDirection("asc");
        }
    };

    const sortedRows = [...currentRows].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue < bValue) {
            return sortDirection === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
    });


    return (
        <div>
            <IconButton onClick={handleOpen}>
                <Tooltip title="Estadísticas">
                    <DonutSmallRounded color="primary" />
                </Tooltip>
            </IconButton>
            <Modal open={open} onClose={handleClose} disableEnforceFocus>
                <Card sx={{ width: 850, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                    <Typography variant="h6" gutterBottom>
                        {buttonTitle}
                    </Typography>
                    <Divider />
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} sm={6}>
                            <div id="chart"></div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={sortField === "first_name"}
                                                            direction={sortDirection as "desc" | "asc" | undefined}
                                                            onClick={() => handleSort("first_name")}
                                                        >
                                                            Nombre
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={sortField === "last_name"}
                                                            direction={sortDirection as "desc" | "asc" | undefined}
                                                            onClick={() => handleSort("last_name")}
                                                        >
                                                            Apellidos
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={sortField === "company_name"}
                                                            direction={sortDirection as "desc" | "asc" | undefined}
                                                            onClick={() => handleSort("company_name")}
                                                        >
                                                            Empresa
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={sortField === "email"}
                                                            direction={sortDirection as "desc" | "asc" | undefined}
                                                            onClick={() => handleSort("email")}
                                                        >
                                                            Correo Electrónico
                                                        </TableSortLabel>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sortedRows.map((user, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{user.first_name}</TableCell>
                                                        <TableCell>{user.last_name}</TableCell>
                                                        <TableCell>{user.company_name}</TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={sortedRows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelRowsPerPage=""
                                        labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`; }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                            Cerrar
                        </Button>
                    </Box>
                </Card>
            </Modal>
        </div>
    );
};

export default ReleasesAnalitics;
