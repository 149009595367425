import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Snackbar,
  Select,
  MenuItem,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../services/api";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import CreateAdvertisement from "./CreateAdvertisement";
import EditAdvertisement from "./EditAdvertisement";

interface ad {
  id: 0;
  name: string;
  zone: string;
  id_sponsor: number;
  img: string;
  url: string;
  id_notice: number
}

interface AdvertisementsProps {
  sponsorId: number;
}

const Advertisements: React.FC<AdvertisementsProps> = ({ sponsorId }) => {
  const [rows, setRows] = useState<ad[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(-1);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [multiple, setMultiple] = useState<number[]>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  useEffect(() => {
    fetchAdvertisement();
  }, [pagination.page, pagination.limit]);

  const fetchAdvertisement = async () => {
    try {
      const response = await api.post(
        `/v1/ad/list?limit=${pagination.limit}&page=${pagination.page}`, {
        id_sponsor: sponsorId
      }
      );
      const { ad, paginate } = response.data;
      setRows(ad);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.paginate.pageCount,
      }));
    } catch (error) {
      console.error("Failed to fetch notices", error);
      setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

      setSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleDelete = async () => {
    try {
      if (multiple.length > 0) {
        await Promise.all(
          multiple.map(async (id) => {
            await api.delete(`/v1/ad/del/${id}`);
          })
        );
      } else {
        await api.delete(`/v1/ad/del/${deleteItemId}`);
      }

      fetchAdvertisement();
      setDeleteItemId(-1);
      closeDialog();
      setMessage("Anuncio eliminado satisfactoriamente");
      setSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      setSeverity("error");
      setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

      setSeverity("error");
      setOpenSnackbar(true);
      console.error("Failed to delete notice", error);
    }
  };

  const zones: { [key: string]: string } = {
    "notice": "Comunicados",
    "post": "Noticias"
  }

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleCheckMultiple = (e: any, id: number) => {
    let newMultiple = [...multiple];
    if (e.target.checked) {
      newMultiple.push(id);
    } else {
      const index = newMultiple.findIndex((idItem) => idItem === id);
      if (index !== -1) {
        newMultiple.splice(index, 1);
      }
    }
    setMultiple(newMultiple);
  };

  const handleCheckAll = (selectAll: boolean) => {
    let newSelected: number[] = [];
    if (selectAll) {
      rows.forEach((rowItem) => {
        newSelected.push(rowItem.id);
      });
    }
    setMultiple(newSelected);
  };

  const handleResultsPerPageChange = (event: any) => {
    const resultsPerPage = parseInt(event.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      limit: resultsPerPage,
    }));
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box textAlign="right">
            <CreateAdvertisement
              sponsorId={sponsorId}
              buttonTitle="Crear"
              onCreate={() => {
                fetchAdvertisement();
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table size="small" className="table-center">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          style={{ width: "5px", height: "5px" }}
                          onChange={(e) => handleCheckAll(e.target.checked)}
                        />
                      </TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Zona</TableCell>
                      <TableCell>Imagen</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length > 0 ? (rows.map((row) => (
                      <TableRow key={row?.id}>
                        {row ? (
                          <>
                            <TableCell>
                              <Checkbox
                                style={{ width: "5px", height: "5px" }}
                                value={multiple.includes(row.id)}
                                checked={multiple.includes(row.id)}
                                onChange={(e) => handleCheckMultiple(e, row.id)}
                              />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{zones[row.zone]}</TableCell>
                            <TableCell>
                              <img src={row.img} style={{ height: "50px" }} />
                            </TableCell>
                            <TableCell>
                              <div style={{ display: "flex" }}>
                                <IconButton
                                  onClick={() => {
                                    setDeleteItemId(row.id);
                                    openDialog();
                                  }}
                                >
                                  <DeleteIcon color="primary" />
                                </IconButton>
                                {
                                  <EditAdvertisement
                                    onUpdate={() => {
                                      fetchAdvertisement();
                                    }}
                                    buttonTitle="Editar Anuncio"
                                    article={row}
                                    openSnackbar={setOpenSnackbar}
                                    setMessage={setMessage}
                                    setSeverity={setSeverity}
                                  />
                                }
                              </div>
                            </TableCell>
                          </>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    ))) : (
                      <TableRow>
                        <TableCell colSpan={6}>No hay anuncios</TableCell>
                      </TableRow>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button
            disabled={currentPage === 1}
            onClick={() => goToPage(currentPage - 1)}
          >
            Anterior
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            Página {pagination.page} de {pagination.total}
          </Box>
          <Button
            disabled={pagination.page === pagination.total}
            onClick={() => goToPage(currentPage + 1)}
          >
            Siguiente
          </Button>
          <Select
            value={pagination.limit} // Valor inicial del número de resultados por página
            onChange={handleResultsPerPageChange}
            style={{ marginLeft: "8px" }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Box>
      </Grid>

      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Realmente deseas eliminar este Anuncio?
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => {
              setDeleteItemId(-1);
              closeDialog();
            }}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleDelete();
              closeDialog();
            }}
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Advertisements;
