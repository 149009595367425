import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField, IconButton, Divider, Grid, Card, Switch, FormControlLabel, TextareaAutosize, Autocomplete, Input, Select, MenuItem, FormControl, InputLabel, Snackbar } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import SponsorSelect from '../SponsorSelect/SponsorSelect';
import api from '../../services/api';
import { AddOutlined } from '@mui/icons-material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { getSponsorTypeName, sponsorTypeList } from '../../pages/SponsorType/CreateSponsorType';

interface CreateUserProps {
    buttonTitle: string;
    onCreate: () => void
}

interface User {
    role: string
    first_name: string
    last_name: string
    email: string
    company_name: string
    password: string
    phone: string
    role_type?: string
}

const CreateUser: React.FC<CreateUserProps> = ({ buttonTitle, onCreate }) => {
    const [open, setOpen] = useState(false);

    const [useHTML, set_useHTML] = useState(false);
    const [users, setUsers] = useState<any[]>([]);
    const [user, setUser] = useState<User>({
        role: '',
        first_name: '', // Provide a default empty string value
        last_name: '',
        email: '',
        company_name: '',
        password: '',
        phone: ''
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState<'error' | 'success'>('error');
    const [message, setMessage] = useState('');
    const [sponsorTypeNames, set_sponsorTypeNames] = useState<any>({})

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const fetchUsers = async () => {
        const response = await api.post(
            `/v1/user/list`, { role: "admin" }
        );
        setUsers(response.data.users);
    }

    const fetchSponsorTypes = async () => {
        const response = await api.post(
            `/v1/role/list`
        );

        const sponsorTypeNamesList: any = {}

        sponsorTypeList.forEach((roleItemObject: string) => {
            const findedType = response.data.role.find((roleItem: any) => {
                return roleItemObject === roleItem.type
            })
            sponsorTypeNamesList[roleItemObject] = findedType?.name
        }, [response])

        set_sponsorTypeNames(sponsorTypeNamesList)

    }

    useEffect(() => {
        if (open) {
            set_useHTML(false)
            fetchUsers()
            getSponsorRoles()
            fetchSponsorTypes()
        }
    }, [open])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [sponsorRoles, set_sponsorRoles] = useState<any>([])

    const getSponsorRoles = async () => {
        const response = await api.post(`/v1/role/list?page=0`);

        const { role } = response.data;

        set_sponsorRoles(role)
    }

    // input handler
    const [file, set_file] = useState<any>(null)

    const handleFile = async (userId: number) => {
        try {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                await api.post('/v1/file/create/user/' + userId, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });

                setUser({
                    role: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    company_name: '',
                    password: '',
                    phone: ''
                });
                // Display success message in the Snackbar

                onCreate();
                handleClose();
                set_file(null)
            }
        } catch (error) {
            console.error('Failed to create sponsor:', error);

        }
    };
    const handleSave = async (e: any) => {
        try {
            e.preventDefault()
            const response = await api.post('/v1/user/create', { ...user, status: false, verified: false });
            await handleFile(response.data.user.id)
            setUser({
                role: '',
                first_name: '',
                last_name: '',
                email: '',
                company_name: '',
                password: '',
                phone: ''
            });

            onCreate();
            handleClose();
            setOpenSnackbar(true);
            setSeverity('success');
            setMessage('Usuario creado exitosamente');
        } catch (error) {
            console.error('Failed to create sponsor:', error);
            // Display error message in the Snackbar
            setOpenSnackbar(true);
            setSeverity('error');
            setMessage((error as any).response?.data?.message as string || "Ha ocurrido un error inesperado, por favor disculpa los inconvenientes.");

        }
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        set_file(file)
    };


    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ color: "white" }}
            >
                {buttonTitle}
                <AddOutlined style={{ marginLeft: "10px" }} />
            </Button>
            <Modal open={open} onClose={handleClose}>
                <form onSubmit={handleSave}>
                    <Card sx={{ width: 700, p: 2, mt: 10, mx: 'auto', bgcolor: 'background.paper' }}>
                        <Typography variant="h6" gutterBottom>
                            {buttonTitle}
                        </Typography>
                        <Divider />

                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    style={{
                                        width: "100%",
                                        boxSizing: "border-box"
                                    }}
                                >
                                    <InputLabel>Rol</InputLabel>
                                    <Select
                                        label="Rol"
                                        value={user.role} // Valor inicial del número de resultados por página
                                        onChange={(e) => setUser({ ...user, role: e.target.value + "" })}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem selected disabled value="rol">Rol</MenuItem>
                                        <MenuItem value="admin">Administrador</MenuItem>
                                        <MenuItem value="member">Miembro</MenuItem>
                                        {
                                            sponsorTypeList.map((sponsorTypeItem, i) => <MenuItem key={i} value={sponsorTypeItem}>
                                                {sponsorTypeNames[sponsorTypeItem]}
                                            </MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nombre"
                                    value={user.first_name}
                                    onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Apellido"
                                    value={user.last_name}
                                    onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Correo"
                                    value={user.email}
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Número Telefónico"
                                    value={user.phone}
                                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Empresa"
                                    value={user.company_name}
                                    onChange={(e) => setUser({ ...user, company_name: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Contraseña"
                                    value={user.password}
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button>
                                    <input type="file" onChange={handleFileChange} />
                                </Button>
                            </Grid>

                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button size="small" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                                Cancelar
                            </Button>
                            <Button
                                sx={{ color: "white" }}
                                size="small" variant="contained" color="primary"
                                type='submit'>
                                Crear
                            </Button>
                        </Box>
                    </Card>
                    {openSnackbar ? "true" : "false"}
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleSnackbarClose}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={handleSnackbarClose}
                            severity={severity}
                        >
                            {message}
                        </MuiAlert>
                    </Snackbar>
                </form>
            </Modal>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </div >
    );
};

export default CreateUser;
